import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class PathologyTestService {
    static list({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };

        return axios.get(apiRoute('/v1/pathology/tests'), requestOptions);
    }

    static otherPathologyTestList({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };

        return axios.get(apiRoute('/v1/other-pathology-test'), requestOptions);
    }

    static pathologyTestAdd(statedata) {
        let paramsdata = JSON.stringify(statedata);

        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute('/v1/pathology/tests'), paramsdata, requestOptions);
    }

    static fetchPathologyTest(pathology_code) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/pathology/tests/' + pathology_code), requestOptions);

    }

    static deletePathologyTest(pathology_code) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.delete(apiRoute('/v1/pathology/tests/' + pathology_code), requestOptions);

    }

    static pathologyTestUpdate(pathology_id, postData) {
        let paramsdata = JSON.stringify(postData);
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.patch(apiRoute('/v1/pathology/tests/' + pathology_id), paramsdata, requestOptions);
    }

    static fetchAllPathologyTest(testType, testCategory = '') {
        let params = {
            test_type: testType,
            test_category: testCategory
        };
        
        const requestOptions = {
            headers: getApiHeader(),
            params: params
        };
       return axios.get(apiRoute('/v1/pathology/tests/price'), requestOptions);
    }

    static updatePathologyTestPrice(postData) {
        let paramsdata = JSON.stringify(postData);

        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute('/v1/pathologytest/price/update'), paramsdata, requestOptions);
    }

    static exportPathologyTestMaster(){
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/pathology-tests/exports/csv'), requestOptions);
    }

    static updateAllPathologyTestPrices(postData){
        const requestOptions = {
            headers: getApiHeader()
        };

        let data = JSON.stringify(postData);

        return axios.post(apiRoute('/v1/update/all-pathology-test/prices'), data, requestOptions);
    }

}

export default PathologyTestService;

