import * as types from './actionTypes';
import PathologyTestService from '../services/pathologyTestService';

export function list({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: types.PATHOLOGY_TEST_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        PathologyTestService.list({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.PATHOLOGY_TEST_LIST_SUCCESS, ...data });
                dispatch({ type: types.ALERT_CLEAR });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function otherPathologyTestList({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: types.PATHOLOGY_TEST_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        PathologyTestService.otherPathologyTestList({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.PATHOLOGY_TEST_LIST_SUCCESS, ...data });
                dispatch({ type: types.ALERT_CLEAR });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function pathologyTestAdd(statedata, history) {
    return dispatch => {
        dispatch({ type: types.ADD_PATHOLOGY_TEST });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        PathologyTestService.pathologyTestAdd(statedata, history)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.ADD_PATHOLOGY_TEST, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Pathology added successfully' });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    history.push('/pathology/test/' + data.id);
                }, 2500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function fetchPathologyTest(pathologyTest_id) {

    return dispatch => {
        PathologyTestService.fetchPathologyTest(pathologyTest_id)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.FETCH_PATHOLOGY_TEST, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}


export function pathologyTestUpdate(pathologyTest_id, statedata, history) {

    return dispatch => {

        PathologyTestService.pathologyTestUpdate(pathologyTest_id, statedata)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.UPDATE_PATHOLOGY_TEST, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Pathology Test updated successfully' });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    history.push('/pathology/test/' + pathologyTest_id);
                }, 3000);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function deletePathologyTest(pathology_code) {

    return dispatch => {
        PathologyTestService.deletePathologyTest(pathology_code)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.DELETE_PATHOLOGY_TEST, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Pathology deleted successfully' });
                setTimeout(() => { dispatch({ type: types.ALERT_CLEAR }); }, 2500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function fetchAllPathologyTest(test_type, test_category) {

    return dispatch => {
        PathologyTestService.fetchAllPathologyTest(test_type, test_category)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.PATHOLOGY_TEST_LIST_REQUEST, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
        
    }
}

export function updatePathologyTestPrice(postData) {

    return dispatch => {
        PathologyTestService.updatePathologyTestPrice(postData)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.ALERT_SUCCESS, message: data.message });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
        }
}