import React from "react";
import { ControlLabel, FormControl } from "react-bootstrap";
import * as CONFIG from "../../utils/config";

const Location = ({ idx, location_detail, handleLocationChange, provider_type = null, validator }) => {
    return (<React.Fragment>
        <div className="box">
            <div className="box-body">
                <div className="row form-group">
                    <div className="col-xs-3">
                        <ControlLabel>Max Profile Allowed: <span>*</span></ControlLabel>
                        <FormControl
                            name='max_profile_allowed'
                            type="number"
                            value={location_detail.max_profile_allowed}
                            placeholder="Enter max profile allowed to user"
                            onChange={(e) => handleLocationChange(e, idx)}
                            autoComplete="none"
                        />
                        {validator.message('max_profile_allowed', location_detail.max_profile_allowed, 'required|numeric|min:1,num|max:50,num')}
                    </div>

                    <div className="col-xs-9">
                        <ControlLabel>Location Name: <span>*</span></ControlLabel>
                        <FormControl
                            name='name'
                            type="text"
                            value={location_detail.name}
                            placeholder="Enter your location name"
                            onChange={(e) => handleLocationChange(e, idx)}
                            autoComplete="none"
                        />
                        {validator.message('name', location_detail.name, 'required|string|max:100,string')}
                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-xs-4">
                        <ControlLabel>Address Line 1: *</ControlLabel>
                        <FormControl
                            name='address_line_1'
                            type="text"
                            value={location_detail.address ? location_detail.address.address_line_1 : ''}
                            placeholder="Enter your address line"
                            onChange={(e) => handleLocationChange(e, idx)}
                            autoComplete="none"
                        />
                        {validator.message('address_line_1', location_detail.address.address_line_1, 'required|string|max:255,string')}
                    </div>

                    <div className="col-xs-4">
                        <ControlLabel>Address Line 2:</ControlLabel>
                        <FormControl
                            name='address_line_2'
                            type="text"
                            value={location_detail.address ? location_detail.address.address_line_2 : ''}
                            placeholder="Enter your address line"
                            onChange={(e) => handleLocationChange(e, idx)}
                            autoComplete="none"
                        />
                        {validator.message('address_line_2', location_detail.address.address_line_2, 'string|max:255,string')}
                    </div>

                    <div className="col-xs-4">
                        <ControlLabel>Address Line 3:</ControlLabel>
                        <FormControl
                            name='address_line_3'
                            type="text"
                            value={location_detail.address ? location_detail.address.address_line_3 : ''}
                            placeholder="Enter your address line"
                            onChange={(e) => handleLocationChange(e, idx)}
                            autoComplete="none"
                        />
                        {validator.message('address_line_3', location_detail.address.address_line_3, 'string|max:255,string')}
                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-xs-4">
                        <ControlLabel>Parish: *</ControlLabel>
                        <FormControl name="parish" componentClass="select" value={location_detail.address ? location_detail.address.parish : ''} onChange={(e) => handleLocationChange(e, idx)} placeholder="Select Parish">
                            <option value=''>Select Parish</option>
                            {CONFIG.PARISH.map((value) => {
                                return <option value={value}>{value}</option>
                            })}
                        </FormControl>
                        {validator.message('parish', location_detail.address.parish, 'required')}
                    </div>

                    <div className="col-xs-4">
                        <ControlLabel>City: *</ControlLabel>
                        <FormControl
                            name='city'
                            type="text"
                            value={location_detail.address ? location_detail.address.city : ''}
                            placeholder="Enter your city"
                            onChange={(e) => handleLocationChange(e, idx)}
                            autoComplete="none"
                        />
                        {validator.message('city', location_detail.address.city, 'required|string|max:100,string')}
                    </div>

                    <div className="col-xs-4">
                        <ControlLabel>Zip Code: </ControlLabel>
                        <FormControl
                            name='pincode'
                            type="text"
                            value={location_detail.address ? location_detail.address.pincode : ''}
                            placeholder="Enter your Zip Code"
                            onChange={(e) => handleLocationChange(e, idx)}
                            autoComplete="none"
                        />
                        {validator.message('pincode', location_detail.address.pincode, 'alpha_num|max:7')}
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-xs-6">
                        <ControlLabel>Contact Number: </ControlLabel>
                        <FormControl
                            name='contact_number'
                            type="text"
                            value={location_detail.contact_number}
                            placeholder="Enter your contact number"
                            onChange={(e) => handleLocationChange(e, idx)}
                            autoComplete="none"
                        />
                        {validator.message('contact number', location_detail.contact_number, 'phone')}
                    </div>
                </div>
                {provider_type === 'covid-centers' && location_detail.logo_url && <div className="row form-group">
                    <div className="col-xs-6">
                        <img src={location_detail.logo_url} height="150" width="150" alt="logo"
                            className="logo-margin" />
                        <br />
                        <FormControl
                            name='logo'
                            type="file"
                            accept=".png,.jpg,.jpeg"
                            onChange={(e) => handleLocationChange(e, idx)}
                        />
                    </div>
                </div>}
            </div>
        </div>
    </React.Fragment>)
}

export default Location;
