import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class RadiologyTestService {
    static list({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };

        return axios.get(apiRoute('/v1/radiology/tests'), requestOptions);
    }

    static otherRadiologyTestList({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };

        return axios.get(apiRoute('/v1/other-radiology-test'), requestOptions);
    }

    static radiologyTestAdd(statedata) {
        let paramsdata = JSON.stringify(statedata);

        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute('/v1/radiology/tests'), paramsdata, requestOptions);
    }

    static fetchRadiologyTest(radiology_code) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/radiology/tests/' + radiology_code), requestOptions);

    }

    static deleteRadiologyTest(radiology_code) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.delete(apiRoute('/v1/radiology/tests/' + radiology_code), requestOptions);

    }

    static radiologyTestUpdate(radiology_id, postData) {
        let paramsdata = JSON.stringify(postData);
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.patch(apiRoute('/v1/radiology/tests/' + radiology_id), paramsdata, requestOptions);
    }

    static fetchAllRadiologyTest(testCategory) {
        let params = {
            test_category: testCategory
        };
        
        const requestOptions = {
            headers: getApiHeader(),
            params: params
        };
       return axios.get(apiRoute('/v1/radiology/tests/price'), requestOptions);
    }

    static updateRadiologyTestPrice(postData) {
        let paramsdata = JSON.stringify(postData);

        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute('/v1/radiologytest/price/update'), paramsdata, requestOptions);
    }

    static exportRadiologyTestMaster(){
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/radiology-tests/exports/csv'), requestOptions);
    }

    static updateAllRadiologyTestPrices(postData){
        const requestOptions = {
            headers: getApiHeader()
        };

        let data = JSON.stringify(postData);

        return axios.post(apiRoute('/v1/update/all-radiology-test/prices'), data, requestOptions);
    }

}

export default RadiologyTestService;

