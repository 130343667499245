import React from 'react';
import { Content, Box } from '../../components/Elements';
import { Helmet } from 'react-helmet';
import { FormGroup, ControlLabel, FormControl, Col } from 'react-bootstrap';
import LocationService from '../../services/locationService';
import * as loaderActions from '../../actions/loaderAction';
import { connect } from 'react-redux';
import Notification from '../../utils/notification';
import SimpleReactValidator from 'simple-react-validator';
import { find } from 'lodash';

class LogoManagementPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: '',
            selected_location: '',
            logo: '',
            currentLogo: ''
        }
        this.validator = new SimpleReactValidator();
    }
    componentDidMount() {
        this.getServiceProvider();
    }

    getServiceProvider = () => {
        this.props.loaderShow();
        LocationService.getServiceProvider().then(resp => {
            this.props.loaderHide();
            this.setState({ locations: resp.data.data })
        }).catch(err => {
            this.props.loaderHide();
            Notification.show('error', err.response.data)
        })
    }

    handleChange = (evt) => {
        let target = evt.target;
        let { selected_location, logo, currentLogo } = this.state
        if (target.name === 'location') {
            selected_location = target.value;
            let provider = find(this.state.locations, function (location) {
                return location.service_provider_id === target.value
            });
            console.log("::::", provider);
            currentLogo = provider ? provider.logo : "";

        } else if (target.name === 'logo') {
            logo = target.files[0];
        }
        this.setState({ selected_location, logo, currentLogo });
    }
    handleSubmit = (e) => {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        const formData = new FormData();
        formData.append('logo', this.state.logo);
        formData.append('service_provider_id', this.state.selected_location);

        this.props.loaderShow();
        LocationService.uploadLogo(formData).then(_resp => {
            this.setState({
                selected_location: '',
                logo: '',
                currentLogo: ''
            })
            this.getServiceProvider();
            document.getElementById("logo").value = "";
            this.props.loaderHide();
            Notification.show('success', { message: 'Logo has been uploaded successfully.' })
        }).catch(err => {
            this.props.loaderHide();
            Notification.show('error', err.response.data)
        })
    }
    render() {
        return <React.Fragment>
            <Helmet>
                <title>Logo Management</title>
            </Helmet>
            <Content title="Logo Management">
                <Box>
                    <Box.Body>
                        <form onSubmit={this.handleSubmit}>
                            <FormGroup className="row" controlId="">
                                <Col sm={2}>
                                    <ControlLabel>Select Covid Center: <span>*</span></ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <FormControl
                                        name='location'
                                        type="text"
                                        onChange={this.handleChange}
                                        componentClass="select"
                                        value={this.state.selected_location ? this.state.selected_location.user_location_id : ''}
                                    >
                                        <option value=''>Select</option>
                                        {this.state.locations && this.state.locations.map(location => (
                                            <option value={location.service_provider_id} key={location.service_provider_id}>{location.name}</option>
                                        ))}
                                    </FormControl>
                                    {this.validator.message('location', this.state.selected_location, 'required')}
                                </Col>
                            </FormGroup>

                            {this.state.currentLogo && <FormGroup className="row" controlId="">
                                <Col sm={2}>
                                    <ControlLabel>Logo: </ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    {this.state.currentLogo != "" ? <img src={this.state.currentLogo} width="200px" /> : "-"}
                                </Col>
                            </FormGroup>
                            }

                            <FormGroup className="row" controlId="logo">
                                <Col sm={2}>
                                    <ControlLabel>Upload Logo: <span>*</span></ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <FormControl
                                        name='logo'
                                        type="file"
                                        accept=".png,.jpg,.jpeg"
                                        onChange={this.handleChange}
                                    />
                                    {this.validator.message('logo', this.state.logo, 'required')}
                                </Col>
                            </FormGroup>
                            <FormGroup className="row" controlId="">
                                <Col sm={6} md={6}>
                                    <button type='submit' className='btn btn-primary'>Add</button>
                                </Col>
                            </FormGroup>
                        </form>
                    </Box.Body>
                </Box>
            </Content>
        </React.Fragment>
    }
}
const mapActionsToProps = ({
    loaderShow: loaderActions.loaderShow,
    loaderHide: loaderActions.loaderHide,
})
export default connect(null, mapActionsToProps)(LogoManagementPage)

