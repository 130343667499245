import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './authReducer';
import alert from './alertReducer';
import doctorList from './doctorListReducer';
import doctor from './doctorReducer';
import patient_list from './patientListReducer';
import pharmacy_list from './pharmacyListReducer';
import pharmacy from './pharmacyReducer';
import drugs_list from './drugListReducer';
import drugs from './drugReducer';
import patient from './patientReducer';
import nurse_list from './nurseListReducer';
import nav from './navReducer';
import adminuser_list from './adminuserListReducer';
import receptionist_list from './receptionistListReducer'
import receptionist from './receptionistReducer';
import admin_user from './adminuserReducer';
import prescription_list from './prescriptionListReducer';
import prescription_view from './orderReducer';
import generic_drugs_list from './genericdrugListReducer';
import generic_drugs from './genericdrugReducer';
import drug_price from './drugPriceReducer';
import search_patient from "./searchpatientReducer";
import card_management_list from "./cardmanagementListReducer";
import generate_cards from "./generatecardReducer";
import user_payments_list from './userPaymentsReducer';
import user_account from "./useraccountReducer";
import commission from "./commissionReducer";
import radiology_list from "./radiologyListReducer";
import radiology from "./radiologyReducer";
import pathology_list from "./pathologyListReducer";
import pathology from "./pathologyReducer";
import pathologytest_list from "./pathologyTestListReducer";
import pathologyTest from "./pathologyTestReducer";
import radiologytest_list from "./radiologyTestListReducer";
import radiologyTest from "./radiologyTestReducer";
import cardBatchList from "./cardBatchListReducer";
import role_management from './roleManagementReducer';
import reissue_card_request from './reissueCardListReducer';
import trial_account from './trialAccountReducer';
import cpt from "./cptListReducer";
import icd from './icdReducer';
import ambulance from './ambulanceReducer';
import emergency_hospital from './emergencyHospitalReducer';
import covid_center from './covidCenterReducer';
import airline from './airlineReducer';
import activity_logs from './activityLogReducer';

export default (history) => combineReducers({
  router: connectRouter(history),
  auth,
  alert,
  doctor_list: doctorList,
  patient_list,
  pharmacy_list,
  pharmacy,
  drugs_list,
  drugs,
  patient,
  nurse_list,
  doctor: doctor,
  nav,
  adminuser_list,
  admin_user,
  receptionist_list,
  receptionist,
  prescription_list,
  prescription_view,
  generic_drugs_list,
  generic_drugs,
  drug_price,
  search_patient,
  card_management_list,
  generate_cards,
  user_payments_list,
  user_account,
  commission,
  radiology_list,
  radiology,
  pathology_list,
  pathology,
  pathologytest_list,
  pathologyTest,
  radiologytest_list,
  radiologyTest,
  cardBatchList,
  role_management,
  reissue_card_request,
  trial_account,
  cpt,
  icd,
  ambulance,
  emergency_hospital,
  covid_center,
  airline,
  activity_logs
});
