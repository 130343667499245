import React from "react";
import { connect } from "react-redux";
import { Content, Box } from "../../components/Elements";
import {
	FormGroup,
	ControlLabel,
	FormControl,
	Col,
	Button,
	ButtonToolbar,
	Table,
} from "react-bootstrap";
import Alert from "../../components/Alert";
import * as PatientAction from "../../actions/patientActions";
import * as CardAction from "../../actions/cardActions";
import PatientService from "../../services/patientService";
import * as Authaction from "../../actions/authActions";
import SimpleReactValidator from "simple-react-validator";
import { Helmet } from "react-helmet";
import * as HELPER from "../../utils/helpers";
import _ from "lodash";
import Notification from "../../utils/notification";
import * as LostReissueComponents from '../../components/LostAndReissue'

class SearchPatientPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			first_name: "",
			last_name: "",
			maiden_name: "",
			date_of_birth: "",
			place_of_birth: "",
			service_provider: [],
			service_provider_location: [],
			patient_id: "",
			delivery: {
				onboarding_source: "",
				parish: "",
				service_provider_id: "",
				user_location_id: "",
				service_provider_name: "",
				user_location_name: "",
			},
			access_code: false,
			generate_card: false,
			patients: '',
			selected_platform: '',
			selected_card: '',
			mobile_update: false,
			patient_mobile_update: '',
			deactiveBlock: false,
			client_error: ''
		};

		this.validator = new SimpleReactValidator();
		this.validator2 = new SimpleReactValidator();
		this.validator3 = new SimpleReactValidator();
		this.cardValidator = new SimpleReactValidator()
		this.handleChange = this.handleChange.bind(this);
		this.handleReIssueCardSubmit = this.handleReIssueCardSubmit.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		this.props.navaction("patient_search");
	}

	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name

		if (['onboarding_source', 'parish', 'service_provider_id', 'user_location_id'].includes(name)) {
			if (!_.isEmpty(value)) {
				let delivery = this.state.delivery;
				delivery[name] = value;
				this.setState({
					delivery: delivery
				}, () => {
					if (['onboarding_source', 'parish'].includes(name) && (!_.isEmpty(this.state.delivery.onboarding_source) && !_.isEmpty(this.state.delivery.parish))) {
						this.getServiceProvidersByParish();
					}

					if (name == "service_provider_id") {
						let location = this.state.service_provider.filter(provider => provider.service_provider_id == value);
						let delivery = this.state.delivery;
						delivery['service_provider_name'] = location[0].service_provider_name;
						this.setState({ service_provider_location: location.length ? location[0]['user_location'] : [], delivery: delivery });
					}

					if (name == "user_location_id") {
						let location = this.state.service_provider_location.filter(location => location.user_location_id == value);
						let delivery = this.state.delivery;
						delivery['user_location_name'] = location[0].name;
						this.setState({ delivery: delivery });
					}
				});
			}
		} else {
			this.setState({
				[name]: value
			});
		}
	}

	getServiceProvidersByParish = platform => {
		let { delivery } = this.state
		PatientService.getServiceProvidersByParish(delivery.onboarding_source, delivery.parish).then(resp => {
			let data = resp.data;
			if (data.message) {
				Notification.show('info', data);
				return false
			}
			delivery['service_provider_id'] = '';
			delivery['user_location_id'] = '';
			delivery['service_provider_name'] = '';
			delivery['user_location_name'] = '';

			this.setState({
				service_provider: data.service_providers,
				access_code: false,
				generate_card: true,
				client_error: data.client_error,
				delivery: delivery
			})
		}).catch(error => {
			Notification.show('error', error.response.data)
		})

	}

	handleChangeContact = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name

		this.setState({
			[name]: value
		});
	}

	handleSubmit = action => {
		// e.preventDefault();
		if (!this.validator.allValid()) {
			this.validator.showMessages();
			this.forceUpdate();
			return false;
		}

		const postData = {
			first_name: this.state.first_name,
			last_name: this.state.last_name,
			maiden_name: this.state.maiden_name,
			date_of_birth: this.state.date_of_birth,
			place_of_birth: this.state.place_of_birth,
		};

		if (action === "generate-access-code") {
			this.setState({
				access_code: true,
				generate_card: false,
				mobile_update: false,
				service_provider: [],
				patient: '',
				service_provider_location: [],
				delivery: {
					service_provider_id: "",
					user_location_id: ""
				}
			});
			this.props.search_patient(postData, this.props.history);
		} else if (action === "lost-reissue-card") {
			PatientService.getActiveCards(postData)
				.then(resp => {
					let { data } = resp;

					if (_.isEmpty(data)) {
						this.setState({
							patient: []
						});
						Notification.show("error", { message: "Patient not found" });
						return false;
					}

					this.setState({
						patient: data,
						patients: data,
						patient_id: data.patient_id,
						access_code: false,
						generate_card: true,
						mobile_update: false,
						service_provider: [],
						service_provider_location: [],
						delivery: {
							service_provider_id: "",
							user_location_id: ""
						},
						selected_platform: '',
						selected_card: '',
						deactiveBlock: false,
						action_type: ''
					});
				})
				.catch(error => {
					if (error.response && error.response.status) {
						let errorData = error.response.data.message;
						Notification.show("error", { message: errorData });
					} else {
						Notification.show("error", {
							message: "Something Went Wrong, Try Again"
						});
					}
				});
		} else if (action === "edit-profile") {
			PatientService.getPatientId(postData).then(resp => {
				this.props.history.push("/patient/" + resp.data.patient_id + "/edit")
			}).catch(error => {
				if (error.response && error.response.status) {
					let errorData = error.response.data.message;
					Notification.show("error", { message: errorData });
				} else {
					Notification.show("error", {
						message: "Something Went Wrong, Try Again"
					});
				}
			});
		} else if (action === "update-mobile") {
			PatientService.getPoojaLoyaltyPatient(postData).then(resp => {
				let { data } = resp;
				this.setState({
					access_code: false,
					generate_card: false,
					mobile_update: true,
					patient_mobile_update: data
				});
			}).catch(error => {
				if (error.response && error.response.status) {
					let errorData = error.response.data.message;
					Notification.show("error", { message: errorData });
				} else {
					Notification.show("error", {
						message: "Something Went Wrong, Try Again"
					});
				}
			})
		}
	};

	handleReIssueCardSubmit = e => {
		e.preventDefault();
		if (!this.validator2.allValid()) {
			this.validator2.showMessages();
			this.forceUpdate();
			return false;
		}
		if (_.isEmpty(this.state.delivery.service_provider_id) || _.isEmpty(this.state.delivery.user_location_id)) {
			Notification.show('error', { message: 'Provider & Provider Location field is required' });
			return false;
		}

		let data = {
			service_provider_id: this.state.delivery.service_provider_id,
			user_location_id: this.state.delivery.user_location_id,
			patient_id: this.state.patient.patient_id,
			platform: this.state.delivery.onboarding_source,
			service_provider_name: this.state.delivery.service_provider_name,
			user_location_name: this.state.delivery.user_location_name,
		};

		this.setState({
			generate_card: false,
			first_name: "",
			last_name: "",
			maiden_name: "",
			date_of_birth: "",
			place_of_birth: "",
			service_provider: [],
			service_provider_location: [],
			delivery: {
				service_provider_id: "",
				user_location_id: "",
				onboarding_source: "",
				parish: "",
				service_provider_name: "",
				user_location_name: "",
			},
			selected_platform: '',
			selected_card: ''
		});
		this.props.reIssueCardRequest(data, this.props.history);
	};

	handleUpdateLocation = e => {
		e.preventDefault();
		if (!this.validator2.allValid()) {
			this.validator2.showMessages();
			this.forceUpdate();
			return false;
		}
		if (_.isEmpty(this.state.delivery.service_provider_id) || _.isEmpty(this.state.delivery.user_location_id)) {
			Notification.show('error', { message: 'Provider & Provider Location field is required' });
			return false;
		}

		let data = {
			service_provider_id: this.state.delivery.service_provider_id,
			user_location_id: this.state.delivery.user_location_id,
			patient_id: this.state.patient.patient_id,
			platform: this.state.delivery.onboarding_source,
			last_platform: this.state.patient.card_reissue_on,
			request_id: this.state.patient.card_reissue_details.request_id,
			service_provider_name: this.state.delivery.service_provider_name,
			user_location_name: this.state.delivery.user_location_name,
		};

		this.setState({
			generate_card: false,
			first_name: "",
			last_name: "",
			maiden_name: "",
			date_of_birth: "",
			place_of_birth: "",
			service_provider: [],
			service_provider_location: [],
			delivery: {
				service_provider_id: "",
				user_location_id: "",
				onboarding_source: "",
				parish: "",
				service_provider_name: "",
				user_location_name: "",
			},
			selected_platform: '',
			selected_card: '',
			action_type: ""
		});
		this.props.updateReissueCardLocation(data, this.props.history);
	};

	onSelectCard = (card) => {
		this.setState({ selected_card: card, patient: card })
	}

	onPlatformSubmit = (action_type) => {


		if (!this.cardValidator.allValid()) {
			this.cardValidator.showMessages();
			this.forceUpdate();
			return false;
		}
		if (_.isEmpty(this.state.selected_card)) {
			Notification.show('error', { message: 'Kindly select card' });
			return false;
		}
		this.setState({ action_type: action_type, deactiveBlock: false });
		if (action_type === "deactivate") {
			this.setState({ deactiveBlock: true });
		}
	}

	resendSms = () => {
		if (!this.cardValidator.allValid()) {
			this.cardValidator.showMessages();
			this.forceUpdate();
			return false;
		}
		if (_.isEmpty(this.state.selected_card)) {
			Notification.show('error', { message: 'Kindly select card' });
			return false;
		}
		PatientService.sendCardReissueSms(this.state.selected_card.patient_id, this.state.selected_card.card_reissue_on).then(resp => {
			Notification.show('success', { message: "SMS sent" });
		}).catch(error => {
			Notification.show('error', error.response.data)
		})
	}

	changeLocation = () => {
		if (!this.cardValidator.allValid()) {
			this.cardValidator.showMessages();
			this.forceUpdate();
			return false;
		}
		if (_.isEmpty(this.state.selected_card)) {
			Notification.show('error', { message: 'Kindly select card' });
			return false;
		}
		this.setState({ action_type: "reissue", deactiveBlock: false });
	}
	handleDeactivateCardSubmit = e => {
		let { selected_card } = this.state
		PatientService.deactivateCard(selected_card.patient_code).then(resp => {
			let data = resp.data;
			if (data.message) {
				Notification.show('info', data);
			}
			this.setState({
				first_name: "",
				last_name: "",
				maiden_name: "",
				date_of_birth: "",
				place_of_birth: "",
				service_provider: {},
				patient_id: "",
				patient: "",
				patients: "",
				access_code: false,
				generate_card: false,
				mobile_update: false,
				action_type: ""
			})
		}).catch(error => {
			Notification.show('error', error.response.data)
		})

	}

	handleContactUpdateSubmit = e => {
		e.preventDefault();
		if (!this.validator3.allValid()) {
			this.validator3.showMessages();
			this.forceUpdate();
			return false;
		}

		const data = {
			patient_code: this.state.patient_mobile_update.patient_code,
			contact: this.state.contact
		};
		PatientService.updateContactPoojaLoyaltyPatient(data).then(resp => {
			let data = resp.data;
			if (data.message) {
				this.setState({
					mobile_update: false,
					patient_mobile_update: '',
					first_name: "",
					last_name: "",
					maiden_name: "",
					date_of_birth: "",
					place_of_birth: "",
				});
				Notification.show('info', data);
			}
		}).catch(error => {
			Notification.show('error', error.response.data)
		})
	}


	setPlatform = (platform) => {
		this.validator2.hideMessages();
		this.forceUpdate();
		this.setState({
			selected_platform: platform,
			delivery: {
				service_provider_id: "",
				user_location_id: ""
			}
		})
	}

	handleCancel = () => {
		this.setState({ generate_card: false, service_provider: [], action_type: "", selected_card: "", deactiveBlock: false })
	}

	handleBack = () => {
		this.setState({ service_provider: [], selected_card: "", deactiveBlock: false, action_type: "" })
	}
	render() {
		const { data } = this.props.patient_data;
		const { patient, patients, patient_mobile_update } = this.state;
		return (
			<div>
				<Helmet>
					<title>Search Patient</title>
				</Helmet>
				<Content title="Search Patient">
					<Box box="solid">
						<Box.Body>
							<Alert />
							<FormGroup className="row" controlId="">
								<Col sm={2}>
									<ControlLabel>
										First Name: <span>*</span>
									</ControlLabel>
								</Col>
								<Col sm={4}>
									<FormControl
										name="first_name"
										type="text"
										placeholder="Enter first name"
										onChange={this.handleChange}
										autoComplete="none"
										value={this.state.first_name}
									/>
									{this.validator.message(
										"first_name",
										this.state.first_name,
										['required', 'string', { regex: HELPER.personNameRegex() }]
									)}
								</Col>
								<Col sm={2}>
									<ControlLabel>
										Last Name: <span>*</span>
									</ControlLabel>
								</Col>
								<Col sm={4}>
									<FormControl
										name="last_name"
										type="text"
										placeholder="Enter last name"
										onChange={this.handleChange}
										autoComplete="none"
										value={this.state.last_name}
									/>
									{this.validator.message(
										"last_name",
										this.state.last_name,
										['required', 'string', { regex: HELPER.personNameRegex() }]
									)}
								</Col>
							</FormGroup>

							<FormGroup className="row" controlId="">
								<Col sm={2}>
									<ControlLabel>
										Maiden Name: <span>*</span>
									</ControlLabel>
								</Col>
								<Col sm={4}>
									<FormControl
										name="maiden_name"
										type="text"
										placeholder="Enter maiden name"
										onChange={this.handleChange}
										autoComplete="none"
										value={this.state.maiden_name}
									/>
									{this.validator.message(
										"maiden_name",
										this.state.maiden_name,
										['required', 'string', { regex: HELPER.personNameRegex() }]
									)}
								</Col>
								<Col sm={2}>
									<ControlLabel>Date Of Birth: *</ControlLabel>
								</Col>
								<Col sm={4}>
									<FormControl
										name="date_of_birth"
										type="date"
										onChange={this.handleChange}
										value={this.state.date_of_birth}
									/>
									{this.validator.message(
										"date_of_birth",
										this.state.date_of_birth,
										"required"
									)}
								</Col>
							</FormGroup>

							<FormGroup className="row" controlId="">
								<Col sm={2}>
									<ControlLabel>Place Of Birth: *</ControlLabel>
								</Col>
								<Col sm={4}>
									<FormControl
										name="place_of_birth"
										type="text"
										placeholder="Enter Place Of Birth"
										onChange={this.handleChange}
										value={this.state.place_of_birth}
									/>
									{this.validator.message(
										"place_of_birth",
										this.state.place_of_birth,
										"required"
									)}
								</Col>
							</FormGroup>
						</Box.Body>
					</Box>
					<FormGroup className="row " controlId="">
						<Col sm={3}></Col>
						{HELPER.hasPermission(this.props.admin, ['generate-access-code']) && <Col sm={2}>
							<ButtonToolbar>
								<Button
									bsStyle="primary"
									type="button"
									onClick={this.handleSubmit.bind(this, "generate-access-code")}
								>
									Generate Access Code
								</Button>
							</ButtonToolbar>
						</Col>}
						{HELPER.hasPermission(this.props.admin, ['lost-and-re-issue-card']) && <Col sm={2}>
							<ButtonToolbar>
								<Button
									bsStyle="primary"
									type="button"
									onClick={this.handleSubmit.bind(this, "lost-reissue-card")}
								>
									Lost & Re-Issue Card
								</Button>
							</ButtonToolbar>
						</Col>}
						{HELPER.hasPermission(this.props.admin, ['patient-edit']) && <Col sm={2}>
							<ButtonToolbar>
								<Button
									bsStyle="primary"
									type="button"
									onClick={this.handleSubmit.bind(this, "edit-profile")}
								>
									Edit Profile
								</Button>
							</ButtonToolbar>
						</Col>}
						{HELPER.hasPermission(this.props.admin, ['patient-edit']) && <Col sm={2}>
							<ButtonToolbar>
								<Button
									bsStyle="primary"
									type="button"
									onClick={this.handleSubmit.bind(this, "update-mobile")}
								>
									Update Contact in Pooja Point
								</Button>
							</ButtonToolbar>
						</Col>}
					</FormGroup>

					{this.state.access_code && data && data.consent_code && (
						<Box box="solid">
							<Box.Head title="Patient access code"></Box.Head>
							<Box.Body>
								<Table responsive striped>
									<tbody>
										<tr>
											<th>Patient Code</th>
											<td>{data.patient_code}</td>
										</tr>
										<tr>
											<th>Access Code</th>
											<td>{data.consent_code}</td>
										</tr>
										<tr>
											<th>Expire At</th>
											<td>{HELPER.getDateTimeFormat(data.expiry_at)}</td>
										</tr>
									</tbody>
								</Table>
							</Box.Body>
						</Box>
					)}

					{this.state.generate_card && !_.isEmpty(this.state.patient) &&
						<Box box="solid">
							<Box.Head title="Lost & Re-Issue Card"></Box.Head>
							<Box.Body>
								{(this.state.action_type !== "reissue" && this.state.patients && this.state.deactiveBlock === false) && <React.Fragment>
									<LostReissueComponents.SelectCard patients={patients} onSelect={this.onSelectCard} onPatientSubmit={this.onPlatformSubmit} selectedCard={this.state.selected_card} resendSms={this.resendSms} changeLocation={this.changeLocation} />
								</React.Fragment>}

								{this.state.action_type === "reissue" && this.state.generate_card && this.state.selected_card && <LostReissueComponents.SelectPlatform
									service_provider={this.state.service_provider}
									patient={patient}
									selected_platform={this.state.selected_platform}
									onChange={this.handleChange}
									service_provider_location={this.state.service_provider_location && this.state.service_provider_location}
									handleChange={this.handleChange}
									handleReIssueCard={this.handleReIssueCardSubmit}
									setPlatform={this.setPlatform}
									handleCancel={this.handleCancel}
									handleBack={this.handleBack}
									delivery={this.state.delivery}
									validator={this.validator2}
									clientError={this.state.client_error}
									handleUpdateLocation={this.handleUpdateLocation}
								/>
								}

								{(this.state.action_type === "deactivate" && !_.isEmpty(this.state.selected_card) && this.state.deactiveBlock === true) && <LostReissueComponents.CardDetails
									patients={patients}
									selected_card={this.state.selected_card}
									handleDeactivateCard={this.handleDeactivateCardSubmit}
									handleCancel={this.handleCancel}
									handleBack={this.handleBack} />}

							</Box.Body>
						</Box>}

					{this.state.mobile_update && !_.isEmpty(patient_mobile_update) && (
						<Box box="solid">
							<Box.Head title="Update Contact Number"></Box.Head>
							<Box.Body>
								<Table responsive bordered>
									<thead>
										<tr>
											<th>Patient Code</th>
											<th>Name</th>
											<th>Contact Number</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>{patient_mobile_update.patient_code}</td>
											<td>{patient_mobile_update.full_name}</td>
											<td>
												<Col sm={4}>
													<FormControl
														name="contact"
														type="text"
														placeholder="Enter Contact Number"
														onChange={this.handleChangeContact}
														value={this.state.contact}
													/>
													{this.validator3.message(
														"contact",
														this.state.contact,
														"required|numeric|phone"
													)}
												</Col>
												<Col sm={4}>
													<Button
														bsStyle="primary"
														type="button"
														onClick={this.handleContactUpdateSubmit}
													> Update </Button>
												</Col>
											</td>
										</tr>
									</tbody>
								</Table>
							</Box.Body>
						</Box>

					)}
				</Content>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	patient_data: state.search_patient,
	admin: state.auth.admin
});

const mapActionsToProps = {
	search_patient: PatientAction.searchPatient,
	navaction: Authaction.navaction,
	reIssueCardRequest: CardAction.reIssueCardRequest,
	updateReissueCardLocation: CardAction.updateReissueCardLocation
};

export default connect(mapStateToProps, mapActionsToProps)(SearchPatientPage);

