import React from 'react';
import { Box } from "../components/Elements";
import { FormGroup, FormControl, Col, Table, Button, ButtonToolbar } from "react-bootstrap";
import * as HELPER from "../utils/helpers"
import _ from "lodash";
import * as CONFIG from "../utils/config";

export const SelectCard = ({ patients, onSelect, onPatientSubmit, selectedCard, resendSms, changeLocation, showDetails }) => {
    return <Box box="solid">
        <Box.Head title="Select Card to Deactivate"></Box.Head>
        <Box.Body>
            <Table responsive bordered>
                <thead>
                    <tr>
                        <th></th>
                        <th>Patient Code</th>
                        <th>Onboarder By</th>
                        <th>Onboarder Date</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {patients.map(patient => {
                        return (<tr>
                            <td>
                                <input
                                    label="1"
                                    name="radio_inp"
                                    type='radio'
                                    id={1}
                                    onClick={() => onSelect(patient)}
                                    checked={_.isEmpty(selectedCard) ? false : true}
                                />
                            </td>
                            <td>{patient.patient_code}</td>
                            <td>{(patient.onboarded_by === 'daisy-health') ? 'Daisy Health' : 'Pooja Loyalty'}</td>
                            <td>{HELPER.getDateFormat(patient.created_at)}</td>
                            <td>{patient.card_request == "new-request" ? "New Request" : (patient.card_request == "pending" ? "Processing Pending" : (patient.card_request == "request-placed" ? "Request Placed" : ""))}</td>
                        </tr>)
                    })}
                </tbody>
            </Table>
            {
                patients.length > 1 && <Button
                    bsStyle="primary"
                    type="button"
                    className="pull-right"
                    onClick={() => onPatientSubmit('deactivate')}
                >
                    Submit
                </Button>
            }

            {(patients.length == 1 && patients[0].card_request == "new-request") && (
                <Button
                    bsStyle="primary"
                    type="button"
                    className="pull-right"
                    onClick={() => onPatientSubmit('reissue')}
                >Submit</Button>
            )}

            {(patients.length == 1 && patients[0].card_request == "pending") && (
                <>
                    <Button
                        bsStyle="primary"
                        type="button"
                        className="pull-right"
                        onClick={() => changeLocation()}
                    >
                        Change Location
                    </Button>
                    <Button
                        bsStyle="primary"
                        type="button"
                        className="pull-right mr-2"
                        onClick={() => resendSms()}
                    >Resend Location SMS</Button>
                </>
            )}

            {(patients.length == 1 && (patients[0].card_request != "pending" && patients[0].card_request != "new-request")) && (
                <><Button
                    bsStyle="primary"
                    type="button"
                    className="pull-right"
                    onClick={() => onPatientSubmit('reissue')}
                >Show Details</Button>
                    {patients[0].card_reissue_on === "daisy-health" && <Button
                        bsStyle="primary"
                        type="button"
                        className="pull-right mr-2"
                        onClick={() => resendSms()}
                    >Resend Location SMS</Button>}
                </>
            )}
        </Box.Body>
    </Box>
}

export const SelectPlatformOld = ({ patient, selected_platform, validator, onChange, onPlatformSubmit }) => {
    return <FormGroup>
        <Button
            bsStyle="primary"
            type="button"
            className="pull-right"
            onClick={onPlatformSubmit}
        >
            Submit
        </Button>
    </FormGroup>
}

export const ServiceProviders = ({ delivery, service_provider, service_provider_location, handleChange, validator, handleReIssueCardSubmit }) => {
    return <Table responsive stripped>
        <tbody>
            <tr>
                <th>Select Doctor</th>
                <td>
                    <FormControl
                        name="service_provider_name"
                        componentClass="select"
                        value={delivery.service_provider_id}
                        onChange={handleChange}
                    >
                        <option value="" selected disabled>---Select Doctor---</option>
                        {service_provider.map(provider => {
                            return (
                                <option value={provider.service_provider_id}>
                                    {provider.service_provider_name}
                                </option>
                            );
                        })}
                    </FormControl>
                    {validator.message(
                        "service_provider_name",
                        delivery.service_provider_id,
                        "required"
                    )}
                </td>
            </tr>
            <tr>
                <th>Select Preferred Doctor's Location</th>
                <td>
                    <FormControl
                        name="location_name"
                        componentClass="select"
                        value={delivery.user_location_id}
                        onChange={handleChange}
                    >
                        <option value="" selected disabled>---Select Location---</option>
                        {service_provider_location.map(
                            provider => {
                                return (
                                    <option value={provider.user_location_id}>
                                        {provider.name}
                                    </option>
                                );
                            }
                        )}
                    </FormControl>
                    {validator.message(
                        "location_name",
                        delivery.user_location_id,
                        "required"
                    )}
                </td>
            </tr>
            <tr>
                <Col sm={3}></Col>
                <Col sm={2}>
                    <ButtonToolbar>
                        <Button
                            bsStyle="primary"
                            type="button"
                            onClick={handleReIssueCardSubmit}
                        >
                            Re-Issue Card
                        </Button>
                    </ButtonToolbar>
                </Col>
            </tr>
        </tbody>
    </Table>
}


export const SelectPlatform = ({ patient, selected_platform, validator, delivery, service_provider, service_provider_location, handleChange, handleReIssueCard, handleUpdateLocation, setPlatform, handleCancel, handleBack, clientError }) => {

    return <Box box="solid">
        <Box.Body>
            <h4 className='text-bold'> Patient Information</h4>
            <hr />

            <Col sm={4}> <b>Patient Code:</b> {patient.patient_code}</Col>
            <Col sm={4}> <b>Onboarded By: </b>{(patient.onboarded_by === 'daisy-health') ? 'Daisy Health' : 'Pooja Loyalty'}</Col>
            <Col sm={4}> <b>Onboarded Date: </b> {HELPER.getDateFormat(patient.created_at)}</Col>

            <h4 className='text-bold  clear-both pd-2'> Select Provider & Location</h4>
            <hr />
            {['new-request', 'pending'].includes(patient.card_request) && (<>
                <Col sm={8}>
                    <Table responsive stripped>
                        <tbody>
                            <tr>
                                <th>Select Onboarding Source</th>
                                <td>
                                    <FormControl
                                        name="onboarding_source"
                                        componentClass="select"
                                        value={delivery.onboarding_source}
                                        onChange={handleChange}
                                    >
                                        <option value="" selected disabled>--- Select Source ---</option>
                                        {(patient.onboarded_by === 'daisy-health')
                                            ? <option value="daisy-health">
                                                Doctor
                                            </option>
                                            : [<option value="daisy-health">
                                                Doctor
                                            </option>, <option value="pooja-loyalty">
                                                Pharmacy
                                            </option>]
                                        }
                                    </FormControl>
                                    {validator.message(
                                        "onboarding_source",
                                        delivery.onboarding_source,
                                        "required"
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>Select Parish</th>
                                <td>
                                    <FormControl
                                        name="parish"
                                        componentClass="select"
                                        value={delivery.parish}
                                        onChange={handleChange}
                                    >
                                        <option value="" selected disabled>--- Select Parish ---</option>
                                        {CONFIG.PARISH.map((value) => {
                                            return <option value={value}>{value}</option>
                                        })}
                                    </FormControl>
                                    {validator.message(
                                        "parish",
                                        delivery.parish,
                                        "required"
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>Select Provider</th>
                                <td>
                                    <FormControl
                                        name="service_provider_id"
                                        componentClass="select"
                                        value={delivery.service_provider_id}
                                        onChange={handleChange}
                                    >
                                        <option value="" selected disabled>--- Select Provider ---</option>
                                        {service_provider.map(
                                            provider => {
                                                return (
                                                    <option value={provider.service_provider_id}>
                                                        {provider.service_provider_name}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </FormControl>
                                    {validator.message(
                                        "provider",
                                        delivery.service_provider_id,
                                        "required"
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>Select Provider's Location (Address)</th>
                                <td>
                                    <FormControl
                                        name="user_location_id"
                                        componentClass="select"
                                        value={delivery.user_location_id}
                                        onChange={handleChange}
                                    >
                                        <option value="" selected disabled>--- Select Provider Location ---</option>
                                        {service_provider_location && service_provider_location.map(
                                            location => {
                                                return (
                                                    <option value={location.user_location_id}>
                                                        {delivery.onboarding_source == "daisy-health" ? (location.address_line_1 + ", " + (location.address_line_2 ? location.address_line_2 : "") + ", " + (location.address_line_3 ? location.address_line_3 : "") + ", " + location.city + ", " + location.pincode) : location.name}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </FormControl>
                                    {validator.message(
                                        "location",
                                        delivery.user_location_id,
                                        "required"
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col sm={4}>
                    {
                        (patient.card_request === "pending" && patient.card_reissue_on === "daisy-health") && (<>
                            <b>Selected Location</b>
                            <p>{patient.card_reissue_details.service_provider.name}</p>
                            <p>{(patient.card_reissue_details.address.address_line_1 + " " + (patient.card_reissue_details.address.address_line_2 ? patient.card_reissue_details.address.address_line_2 : "") + " " + (patient.card_reissue_details.address.address_line_3 ? patient.card_reissue_details.address.address_line_3 : "") + ", " + patient.card_reissue_details.address.city + ", " + patient.card_reissue_details.address.pincode)}</p>
                        </>
                        )
                    }
                    {
                        (patient.card_request === "pending" && patient.card_reissue_on === "pooja-loyalty") && (<>
                            <b>Selected Location</b>
                            <p>{patient.card_reissue_details.location_info.service_provider_name}</p>
                            <p>{(patient.card_reissue_details.location_info.location_name)}</p>
                        </>
                        )
                    }
                </Col>
                <Col sm={12} className="pull-right">
                    <ButtonToolbar className='mt-2 pull-right'>
                        <Button
                            bsStyle="primary"
                            type="button"
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                        <Button
                            bsStyle="primary"
                            type="button"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        {patient.card_request === "pending" ? (<Button
                            bsStyle="primary"
                            type="button"
                            onClick={handleUpdateLocation}
                            disabled={(delivery.service_provider_id == patient.card_reissue_details.delivered_service_provider_id && delivery.user_location_id == patient.card_reissue_details.delivered_user_location_id) ? true : false}
                        >
                            Update Location
                        </Button>) : (<Button
                            bsStyle="primary"
                            type="button"
                            onClick={handleReIssueCard}
                        >
                            Re-Issue Card
                        </Button>)}

                    </ButtonToolbar>
                </Col>
            </>)}

            {['request-placed'].includes(patient.card_request) && (<>

                <Col sm={8}>
                    {patient.card_reissue_on === "daisy-health" && (<>

                        <Col sm={3}>
                            <b>Selected Location</b>
                        </Col>
                        <Col sm={8}>
                            <b>{patient.card_reissue_details.service_provider.name}</b><br /><br />
                            <p>{(patient.card_reissue_details.address.address_line_1 + " " + (patient.card_reissue_details.address.address_line_2 ? patient.card_reissue_details.address.address_line_2 : "") + " " + (patient.card_reissue_details.address.address_line_3 ? patient.card_reissue_details.address.address_line_3 : ""))}</p>
                            <p>{patient.card_reissue_details.address.city + ", " + patient.card_reissue_details.address.pincode}</p>
                        </Col>
                    </>
                    )}

                    {patient.card_reissue_on === "pooja-loyalty" && (<>

                        <Col sm={3}>
                            <b>Selected Location</b>
                        </Col>
                        <Col sm={8}>
                            <b>{patient.card_reissue_details.location_info.service_provider_name}</b><br /><br />
                            <p>{(patient.card_reissue_details.location_info.location_name)}</p>
                        </Col>
                    </>
                    )}
                </Col>
                <Col sm={12} className="pull-right">
                    <ButtonToolbar className='mt-2 pull-right'>
                        <Button
                            bsStyle="primary"
                            type="button"
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                        <Button
                            bsStyle="primary"
                            type="button"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>

                    </ButtonToolbar>
                </Col>

            </>)}
        </Box.Body>
    </Box>
}


export const CardDetails = ({ patients, selected_card, handleDeactivateCard, handleCancel, handleBack }) => {
    return <Box box="solid">
        <Box.Body>
            <h4 className='text-bold'> Active Card Detail</h4>
            <hr />
            <Col sm={12}>
                <p>Note: This Patient already have another card, following are the details:</p>

                <Col sm={12}>
                    {patients.map(patient => {
                        return patient.patient_code !== selected_card.patient_code && <Table responsive stripped style={{ border: "2px solid #999", marginTop: "5px" }}>
                            <tbody>
                                <tr>
                                    <td>Patient code:</td>
                                    <td>{patient.patient_code}</td>
                                </tr>
                                <tr>
                                    <td>Onboarded by:</td>
                                    <td>{patient.onboarded_by === 'daisy-health' ? 'Daisy Health' : 'Pooja Loyalty'}</td>
                                </tr>
                                <tr>
                                    <td>Onboarded Date:</td>
                                    <td>{HELPER.getDateFormat(patient.created_at)}</td>
                                </tr>
                                <tr>
                                    <td>Onboarding Provider:</td>
                                    <td>{patient.service_provider && patient.service_provider.name}</td>
                                </tr>

                            </tbody>
                        </Table>

                    })}
                </Col>
                <p className='text-bold'>Hereafter, This card will be the activated card for further use.</p>
            </Col>
            <h4 className='text-bold clear-both pd-1'>Card to Deactivate</h4>
            <hr />

            <Col sm={4}> Patient Code: {selected_card.patient_code}</Col>
            <Col sm={4}> Onboarded By: {(selected_card.onboarded_by === 'daisy-health') ? 'Daisy Health' : 'Pooja Loyalty'}</Col>
            <Col sm={4}> Onboarded Date: {HELPER.getDateFormat(selected_card.created_at)}</Col>


            <Col sm={12} className="pull-right">
                <ButtonToolbar className='mt-2 pull-right'>
                    <Button
                        bsStyle="primary"
                        type="button"
                        onClick={handleBack}
                    >
                        Back
                    </Button>
                    <Button
                        bsStyle="primary"
                        type="button"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        bsStyle="primary"
                        type="button"
                        onClick={handleDeactivateCard}
                    >
                        Deactivate Card
                    </Button>
                </ButtonToolbar>
            </Col>
        </Box.Body>
    </Box>
}