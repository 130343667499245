import * as types from './actionTypes';
import RadiologyTestService from '../services/radiologyTestService';

export function list({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: types.RADIOLOGY_TEST_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        RadiologyTestService.list({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.RADIOLOGY_TEST_LIST_SUCCESS, ...data });
                dispatch({ type: types.ALERT_CLEAR });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function otherRadiologyTestList({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: types.RADIOLOGY_TEST_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        RadiologyTestService.otherRadiologyTestList({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.RADIOLOGY_TEST_LIST_SUCCESS, ...data });
                dispatch({ type: types.ALERT_CLEAR });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function radiologyTestAdd(statedata, history) {
    return dispatch => {
        dispatch({ type: types.ADD_RADIOLOGY_TEST });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        RadiologyTestService.radiologyTestAdd(statedata, history)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.ADD_RADIOLOGY_TEST, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Radiology added successfully' });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    history.push('/radiology/test/' + data.id);
                }, 2500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function fetchRadiologyTest(pathologyTest_id) {

    return dispatch => {
        RadiologyTestService.fetchRadiologyTest(pathologyTest_id)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.FETCH_RADIOLOGY_TEST, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}


export function radiologyTestUpdate(radiologyTest_id, statedata, history) {

    return dispatch => {

        RadiologyTestService.radiologyTestUpdate(radiologyTest_id, statedata)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.UPDATE_RADIOLOGY_TEST, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Radiology Test updated successfully' });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    history.push('/radiology/test/' + radiologyTest_id);
                }, 3000);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function deleteRadiologyTest(radiology_code) {

    return dispatch => {
        RadiologyTestService.deleteradiologyTest(radiology_code)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.DELETE_RADIOLOGY_TEST, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Radiology deleted successfully' });
                setTimeout(() => { dispatch({ type: types.ALERT_CLEAR }); }, 2500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function fetchAllRadiologyTest(test_category) {

    return dispatch => {
        RadiologyTestService.fetchAllRadiologyTest(test_category)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.RADIOLOGY_TEST_LIST_REQUEST, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
        
    }
}

export function updateRadiologyTestPrice(postData) {

    return dispatch => {
        RadiologyTestService.updateRadiologyTestPrice(postData)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.ALERT_SUCCESS, message: data.message });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
        }
}