import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Content, Box } from '../../components/Elements';
import * as PatientAction from '../../actions/patientActions';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';
import * as CONFIG from "../../utils/config";
import Alert from '../../components/Alert';
import _ from "lodash";
import * as HELPER from '../../utils/helpers';

class EditPatientPage extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            patientId: "",
            title: '',
            first_name: "",
            middle_name: "",
            last_name: "",
            maiden_name: "",
            email: "",
            contact_number: "",
            alt_contact_number: "",
            gender: "",
            card_number: "",
            healthcard_number: "",
            date_of_birth: "",
            address_line_1: "",
            address_line_2: "",
            address_line_3: "",
            city: "",
            pincode: "",
            parish: "",
            insurance_type: [],
            is_jadep: false,
            is_nhf: false,
            is_goj: false,
            jadep_number: null,
            nhf_number: null,
            goj_number: null,
            is_no: false,
            is_private: false,
            flag: true,
            isEditing: false,
            place_of_birth: '',
            blood_group: '',
            allergy: '',
            height: '',
            kin_name: '',
            kin_number: '',
            nearest_police_station: ''
        };


        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInsuranceTypeChange = this.handleInsuranceTypeChange.bind(this);
        this.validator = new SimpleReactValidator();

        const { match: { params } } = this.props;
        const patientId = params.patientId;

        this.props.fetchPatient(patientId);
    }


    componentWillReceiveProps = (props, state) => {
        if (this.state.flag === true) {

            this.setState({
                patientId: props.patient.patient_id,
                title: props.patient.name_title,
                first_name: props.patient.first_name,
                middle_name: props.patient.middle_name,
                last_name: props.patient.last_name,
                maiden_name: props.patient.maiden_name,
                email: props.patient.email,
                contact_number: props.patient.contact_number,
                alt_contact_number: props.patient.alt_contact_number,
                gender: props.patient.gender,
                card_number: props.patient.card_number,
                healthcard_number: props.patient.healthcard_number,
                insurance_type: props.patient.insurance_type ? props.patient.insurance_type : [],
                jadep_number: props.patient.jadep_number,
                nhf_number: props.patient.nhf_number,
                goj_number: props.patient.goj_number,
                date_of_birth: props.patient.date_of_birth,
                address_line_1: props.patient.address && props.patient.address.address_line_1,
                address_line_2: props.patient.address && props.patient.address.address_line_2,
                address_line_3: props.patient.address && props.patient.address.address_line_3,
                parish: props.patient.address && props.patient.address.parish,
                city: props.patient.address && props.patient.address.city,
                pincode: props.patient.address && props.patient.address.pincode,
                is_jadep: props.patient.insurance_type && props.patient.insurance_type.includes('jadep') ? true : false,
                is_nhf: props.patient.insurance_type && props.patient.insurance_type.includes('nhf') ? true : false,
                is_goj: props.patient.insurance_type && props.patient.insurance_type.includes('goj') ? true : false,
                is_private: props.patient.insurance_type && props.patient.insurance_type.includes('private-insurance') ? true : false,
                is_no: props.patient.insurance_type && props.patient.insurance_type.includes('no') ? true : false,
                flag: false,
                isEditing: true,
                place_of_birth: props.patient.place_of_birth,
                blood_group: props.patient.blood_group,
                height: props.patient.height,
                allergy: props.patient.allergy,
                kin_name: props.patient.kin_name,
                kin_number: props.patient.kin_number,
                nearest_police_station: props.patient.nearest_police_station
            });
        } else {
            return state;
        }
    }

    onChange(e) {
        const name = e.target.name
        const value = e.target.value
        this.setState({
            [name]: value
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        let data = {
            card_number: this.state.card_number,
            name_title: this.state.title,
            first_name: this.state.first_name,
            middle_name: this.state.middle_name,
            last_name: this.state.last_name,
            maiden_name: this.state.maiden_name,
            date_of_birth: this.state.date_of_birth,
            gender: this.state.gender,
            email: this.state.email,
            contact_number: this.state.contact_number,
            alt_contact_number: this.state.alt_contact_number,
            healthcard_number: this.state.healthcard_number,
            insurance_type: this.state.insurance_type,
            jadep_number: this.state.is_jadep ? this.state.jadep_number : '',
            nhf_number: this.state.is_nhf ? this.state.nhf_number : '',
            goj_number: this.state.is_goj ? this.state.goj_number : '',
            address: {
                address_line_1: this.state.address_line_1,
                address_line_2: this.state.address_line_2,
                address_line_3: this.state.address_line_3,
                city: this.state.city,
                pincode: this.state.pincode,
                parish: this.state.parish
            },
            place_of_birth: this.state.place_of_birth ? this.state.place_of_birth : '',
            blood_group: this.state.blood_group ? this.state.blood_group : '',
            height: this.state.height ? this.state.height : '',
            allergy: this.state.allergy ? this.state.allergy : '',
            kin_name: this.state.kin_name ? this.state.kin_name : '',
            kin_number: this.state.kin_number ? this.state.kin_number : '',
            nearest_police_station: this.state.nearest_police_station ? this.state.nearest_police_station : ''
        };
        this.props.patientupdate(data, this.state.patientId, this.props.history);
    }

    handleInsuranceTypeChange(e) {
        let insurance_type = this.state.insurance_type;

        const { is_no, is_private } = this.state;

        if (e.target.value === "no") {
            insurance_type = ["no"];
            this.setState({
                is_no: !is_no,
                is_jadep: e.target.unchecked,
                is_nhf: e.target.unchecked,
                is_goj: e.target.unchecked,
                is_private: e.target.unchecked,
                jadep_number: "",
                nhf_number: "",
                goj_number: ""
            });
        } else {
            _.pull(insurance_type, "no");
        }

        if (e.target.checked) {
            insurance_type.push(e.target.value);
        } else {
            if (e.target.value === 'jadep') {
                this.setState({
                    jadep_number: null
                });
            }
            if (e.target.value === 'nhf') {
                this.setState({
                    nhf_number: null
                });
            }
            if (e.target.value === 'goj') {
                this.setState({
                    goj_number: null
                });
            }
            _.pull(insurance_type, e.target.value);
        }

        if (e.target.value === 'private-insurance') {
            this.setState({
                is_private: !is_private,
                is_no: false
            });
        }
        if (e.target.value === 'jadep') {
            this.setState({
                is_no: false,
                is_jadep: e.target.checked
            });
        }
        if (e.target.value === 'nhf') {
            this.setState({
                is_no: false,
                is_nhf: e.target.checked
            });
        }
        if (e.target.value === 'goj') {
            this.setState({
                is_no: false,
                is_goj: e.target.checked
            });
        }
        if (e.target.value === 'no') {
            insurance_type = ['no'];
            this.setState({
                is_no: !is_no,
                is_jadep: e.target.unchecked,
                is_nhf: e.target.unchecked,
                is_goj: e.target.unchecked,
                is_private: e.target.unchecked,
                jadep_number: null,
                nhf_number: null,
                goj_number: null
            });
        } else {
            _.pull(insurance_type, 'no');
        }

        this.setState({ insurance_type: insurance_type });
    }

    render() {
        this.validator.purgeFields();

        return <div>
            <Helmet>
                <title>Edit patient</title>
            </Helmet>
            <Content title="Edit patient">
                <Box>
                    <Box.Head title="Edit patient Form"></Box.Head>
                    <Box.Body>
                        <Alert />
                        {this.state && this.props.patient.patient_id &&
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <fieldset className="form-group">
                                            <label htmlFor="first_name">Title*</label>
                                            <input type="text" className="form-control" name="first_name" value={this.state.title} id="first_name" onChange={this.onChange} disabled />
                                            {this.validator.message('first_name', this.state.first_name, 'required|alpha_space')}
                                        </fieldset>
                                    </div>

                                    <div className="col-sm-3">
                                        <fieldset className="form-group">
                                            <label htmlFor="first_name">First Name*</label>
                                            <input type="text" className="form-control" name="first_name" value={this.state.first_name} id="first_name" onChange={this.onChange} disabled />
                                            {this.validator.message('first_name', this.state.first_name, ['required', 'string', { regex: HELPER.personNameRegex() }])}
                                        </fieldset>
                                    </div>

                                    <div className="col-sm-3">
                                        <fieldset className="form-group">
                                            <label htmlFor="middle_name">Middle Name</label>
                                            <input type="text" className="form-control" value={this.state.middle_name} id="middle_name" name="middle_name" onChange={this.onChange} disabled />
                                            {this.validator.message('middle_name', this.state.middle_name, ['', 'string', { regex: HELPER.personNameRegex() }])}
                                        </fieldset>
                                    </div>

                                    <div className="col-sm-3">
                                        <fieldset className="form-group">
                                            <label htmlFor="last_name">Last Name*</label>
                                            <input type="text" className="form-control" value={this.state.last_name} id="last_name" name="last_name" onChange={this.onChange} disabled />
                                            {this.validator.message('last_name', this.state.last_name, ['required', 'string', { regex: HELPER.personNameRegex() }])}
                                        </fieldset>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <fieldset className="form-group">
                                            <label htmlFor="mother_maiden_name">Mother Maiden Name*</label>
                                            <input type="text" className="form-control" value={this.state.maiden_name} id="mother_maiden_name" name="mother_maiden_name" onChange={this.onChange} disabled />
                                            {this.validator.message('mother_maiden_name', this.state.maiden_name, ['required', 'string', { regex: HELPER.personNameRegex() }])}
                                        </fieldset>
                                    </div>

                                    <div className="col-sm-4">
                                        <fieldset className="form-group">
                                            <label htmlFor="date_of_birth">Date Of Birth*</label>
                                            <input type="date" className="form-control" value={this.state.date_of_birth} id="date_of_birth" name="date_of_birth" onChange={this.onChange} disabled />
                                            {this.validator.message('date_of_birth', this.state.date_of_birth, 'required')}
                                        </fieldset>
                                    </div>

                                    <div className="col-sm-4">
                                        <fieldset className="form-group">
                                            <label htmlFor="place_of_birth">Place Of Birth* </label>
                                            <input type="text" className="form-control" value={_.capitalize(this.state.place_of_birth)} id="place_of_birth" name="place_of_birth" onChange={this.onChange} disabled />
                                        </fieldset>
                                    </div>

                                    <div className="col-sm-4">
                                        <fieldset className="form-group">
                                            <label htmlFor="gender">Gender*</label>
                                            <select className="form-control" id="control" name="gender" onChange={this.onChange} disabled>
                                                <option value="male" selected={this.state.gender === 'male' ? 'selected' : ''}>Male</option>
                                                <option value="female" selected={this.state.gender === 'female' ? 'selected' : ''}>Female</option>
                                            </select>
                                            {this.validator.message('gender', this.state.gender, 'required')}
                                        </fieldset>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <fieldset className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input type="text" className="form-control" value={this.state.email} id="email" name="email" onChange={this.onChange} />
                                            {this.validator.message('email', this.state.email, 'email')}
                                        </fieldset>
                                    </div>

                                    <div className="col-sm-4">
                                        <fieldset className="form-group">
                                            <label htmlFor="contact_number">Contact Number*</label>
                                            <input type="text" className="form-control" value={this.state.contact_number} id="contact_number" name="contact_number" onChange={this.onChange} />
                                            {this.validator.message('contact_number', this.state.contact_number, 'required|phone')}
                                        </fieldset>
                                    </div>

                                    <div className="col-sm-4">
                                        <fieldset className="form-group">
                                            <label htmlFor="alt_contact_number">Alternate Number/Landline Number</label>
                                            <input type="text" className="form-control" value={this.state.alt_contact_number} id="alt_contact_number" name="alt_contact_number" onChange={this.onChange} />
                                            {this.validator.message('alt_contact_number', this.state.alt_contact_number, 'phone')}
                                        </fieldset>
                                    </div>
                                </div>

                                <div className="row">
                                    {this.state.is_jadep && <div className="col-sm-4">
                                        <fieldset className="form-group">
                                            <label htmlFor="jadep_number">Jadep Number*</label>
                                            <input type="text" className="form-control" value={this.state.jadep_number} id="jadep_number" name="jadep_number" onChange={this.onChange} />
                                            {this.state.is_jadep && this.state.insurance_type.includes('jadep') ?
                                                this.validator.message('jadep_number', this.state.jadep_number, 'required|string|max:100') : ''
                                            }
                                        </fieldset>
                                    </div>}

                                    {this.state.is_nhf && <div className="col-sm-4">
                                        <fieldset className="form-group">
                                            <label htmlFor="nhf_number">NHF Number*</label>
                                            <input type="text" className="form-control" value={this.state.nhf_number} id="nhf_number" name="nhf_number" onChange={this.onChange} />
                                            {this.state.is_nhf && this.state.insurance_type.includes('nhf') ? this.validator.message('nhf_number', this.state.nhf_number, 'required|string|max:100') : ''}
                                        </fieldset>
                                    </div>}

                                    {this.state.is_goj && <div className="col-sm-4">
                                        <fieldset className="form-group">
                                            <label htmlFor="goj_number">GOJ Number*</label>
                                            <input type="text" className="form-control" value={this.state.goj_number} id="goj_number" name="goj_number" onChange={this.onChange} />
                                            {this.state.is_goj && this.state.insurance_type.includes('goj') ? this.validator.message('goj_number', this.state.goj_number, 'required|string|max:100') : ''}
                                        </fieldset>
                                    </div>}
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <fieldset className="form-group">
                                            <label htmlFor="address_line_1">Address 1*</label>
                                            <input type="text" className="form-control" value={this.state.address_line_1} id="address_line_1" name="address_line_1" onChange={this.onChange} />
                                            {this.validator.message('address_line_1', this.state.address_line_1, 'required|max:100')}
                                        </fieldset>
                                    </div>

                                    <div className="col-sm-12">
                                        <fieldset className="form-group">
                                            <label htmlFor="address_line_2">Address 2</label>
                                            <input type="text" className="form-control" value={this.state.address_line_2} id="address_line_2" name="address_line_2" onChange={this.onChange} />
                                            {this.validator.message('address_line_2', this.state.address_line_2, 'string|max:100')}
                                        </fieldset>
                                    </div>

                                    <div className="col-sm-12">
                                        <fieldset className="form-group">
                                            <label htmlFor="address_line_3">Address 3</label>
                                            <input type="text" className="form-control" value={this.state.address_line_3} id="address_line_3" name="address_line_3" onChange={this.onChange} />
                                            {this.validator.message('address_line_3', this.state.address_line_3, 'string|max:100')}
                                        </fieldset>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <fieldset className="form-group">
                                            <label htmlFor="parish">Parish*</label>
                                            <select className="form-control" value={this.state.parish} id="parish" name="parish" onChange={this.onChange}>
                                                <option value="" disabled>Select Parish</option>
                                                {Object.keys(CONFIG.PARISH).map(function (key) {
                                                    return <option className="travelcompany-input" value={CONFIG.PARISH[key]}>
                                                        {CONFIG.PARISH[key]}
                                                    </option>;
                                                })}
                                            </select>
                                            {this.validator.message('parish', this.state.parish, 'required')}
                                        </fieldset>
                                    </div>

                                    <div className="col-sm-4">
                                        <fieldset className="form-group">
                                            <label htmlFor="city">City*</label>
                                            <input type="text" className="form-control" value={this.state.city} id="city" name="city" onChange={this.onChange} />
                                            {this.validator.message('city', this.state.city, 'required|alpha_num_dash_space')}
                                        </fieldset>
                                    </div>

                                    <div className="col-sm-4">
                                        <fieldset className="form-group">
                                            <label htmlFor="pincode">Zip Code*</label>
                                            <input type="text" className="form-control" value={this.state.pincode} id="pincode" name="pincode" onChange={this.onChange} />
                                            {this.validator.message('pincode', this.state.pincode, 'alpha_num')}
                                        </fieldset>
                                    </div>

                                    <div className="col-sm-4">
                                        <fieldset className="form-group">
                                            <label htmlFor="kin_name">Name of Kin</label>
                                            <input type="text" className="form-control" value={this.state.kin_name} id="kin_name" name="kin_name" onChange={this.onChange} />
                                        </fieldset>
                                    </div>

                                    <div className="col-sm-4">
                                        <fieldset className="form-group">
                                            <label htmlFor="kin_number">Kin Contact Number</label>
                                            <input type="text" className="form-control" value={this.state.kin_number} id="kin_number" name="kin_number" onChange={this.onChange} />
                                            {this.validator.message('kin_number', this.state.kin_number, 'phone')}
                                        </fieldset>
                                    </div>

                                    <div className="col-sm-4">
                                        <fieldset className="form-group">
                                            <label htmlFor="nearest_police_station">Nearest Police Station*</label>
                                            <input type="text" className="form-control" value={this.state.nearest_police_station} id="nearest_police_station" name="nearest_police_station" onChange={this.onChange} />
                                        </fieldset>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                        <Link to="/patients/search" className="btn btn-secondry">Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        }
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    patient: state.patient
});

const mapActionsToProps = ({
    fetchPatient: PatientAction.fetchpatient,
    patientupdate: PatientAction.patientupdate,
});

export default connect(mapStateToProps, mapActionsToProps)(EditPatientPage);
