import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { FormGroup, ControlLabel, FormControl, Radio, Col, Button, ButtonToolbar } from 'react-bootstrap';
import * as DoctorAction from '../../actions/doctorActions';
import * as Authaction from '../../actions/authActions';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';
import * as CONFIG from '../../utils/config';
import Alert from '../../components/Alert';
import _ from "lodash";
import Notification from '../../utils/notification';
import * as HELPER from '../../utils/helpers';
import AddMultiLocation from '../../components/Location/AddMultiLocation';
import AddCommission from '../../components/Commission/AddCommission';

class AddDoctorPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            first_name: "",
            last_name: "",
            email: "",
            contact_number: "",
            alt_contact_number: "",
            gender: "male",
            date_of_birth: "",
            degree: "",
            specialization: "",
            license_number: "",
            commission_rate: "",
            address_line_1: "",
            address_line_2: "",
            address_line_3: "",
            city: "",
            pincode: "",
            parish: "",
            other_degree: false,
            other_specialization: false,
            is_trn_applicable: false,
            no_commission: false,
            trn_number: '',
            other_degree_name: '',
            other_specialization_name: '',
            location_details: [{
                address: {
                    address_line_1: '',
                    address_line_2: '',
                    address_line_3: '',
                    parish: '',
                    city: '',
                    pincode: ''
                },
                name: '',
                max_profile_allowed: '',
            }],
            commission_details: {
                no_commission: false,
                commission_type: '',
                commission_percentage: '',
                cost_per_use: "",
                currency: "",
            },
        };

        this.validator = new SimpleReactValidator({
            validators: {
                birthdate: {
                    message: 'The date of birth must be less than todays date',
                    rule: (val, params) => {
                        return (new Date(val) > new Date(params) ? false : true)
                    },
                    required: true
                }
            }
        });

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
    }

    componentDidMount() {
        this.props.navaction("user_management", "doctor");
    }

    addMoreLocationBlock = (location_details) => {
        this.setState({ location_details })
    }

    removeLocationBlock = (location_details) => {
        this.setState({ location_details })
    }

    handleLocationChange = (location_details) => {
        this.setState({ location_details: location_details });
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        if (name === "is_trn_applicable") {
            if (event.target.checked) {
                this.setState({
                    is_trn_applicable: true
                });
            } else {
                this.setState({
                    is_trn_applicable: false
                });
            }
        }

        if (name === "degree") {
            if (value === "other_degree") {
                this.setState({
                    other_degree: true,
                })
            } else {
                this.setState({
                    other_degree: false,
                })
            }
        }

        if (name === "specialization") {
            if (value === "other_specialization") {
                this.setState({
                    other_specialization: true,
                })
            } else {
                this.setState({
                    other_specialization: false
                });
            }
        }
    }

    handleCommissionChange = (key, value) => {

        if (key === "no_commission") {
            value = !this.state.commission_details.no_commission
        }
        this.setState(
            prevState => ({
                commission_details: {
                    ...prevState.commission_details,
                    [key]: value
                }
            })
        )
    }

    handleRadioChange = (event) => {
        this.setState({
            gender: event.currentTarget.value
        })
    };

    resetForm() {
        this.setState({
            first_name: "",
            last_name: "",
            email: "",
            contact_number: "",
            alt_contact_number: "",
            gender: "male",
            date_of_birth: "",
            degree: "",
            specialization: "",
            license_number: "",
            commission_rate: "",
            address_line_1: "",
            address_line_2: "",
            address_line_3: "",
            city: "",
            pincode: "",
            parish: "",
            other_degree: false,
            other_specialization: false,
            is_trn_applicable: false,
            no_commission: false,
            trn_number: '',
            other_degree_name: '',
            other_specialization_name: '',
            location_details: [{
                address: {
                    address_line_1: '',
                    address_line_2: '',
                    address_line_3: '',
                    parish: '',
                    city: '',
                    pincode: ''
                },
                name: '',
                max_profile_allowed: '',
            }],
            commission_details: {
                no_commission: false,
                commission_type: '',
                commission_percentage: '',
                cost_per_use: "",
                currency: "",
            },
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        if (this.state.is_trn_applicable === true) {
            if (_.isEmpty(this.state.trn_number)) {
                Notification.show('error', { message: "Please enter TRN Number" });
                return false;
            }
        }

        if (HELPER.hasRole(this.props.admin, ['super-admin', 'admin', 'business-development-head']) && _.isEmpty(this.state.commission_details.commission_type) && !this.state.commission_details.no_commission) {
            Notification.show('error', { message: "Please enter Commission details" });
            return false;
        }

        let postData = {
            name_title: this.state.name_title,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            gender: this.state.gender,
            date_of_birth: this.state.date_of_birth,
            trn_number: this.state.trn_number,
            contact_number: this.state.contact_number,
            alt_contact_number: this.state.alt_contact_number,
            email: this.state.email,
            license_number: this.state.license_number,
            is_trn_applicable: this.state.is_trn_applicable,
            specialization: this.state.other_specialization ? this.state.other_specialization_name : this.state.specialization,
            degree: this.state.other_degree ? this.state.other_degree_name : this.state.degree,
            billing_address: {
                address_line_1: this.state.address_line_1,
                address_line_2: this.state.address_line_2,
                address_line_3: this.state.address_line_3,
                city: this.state.city,
                pincode: this.state.pincode,
                parish: this.state.parish
            },
            billing_name: this.state.billing_name,
            locations: this.state.location_details,
            no_commission: this.state.commission_details.no_commission,
        };

        if (this.state.commission_details.no_commission === false) {
            postData['commission_type'] = this.state.commission_details.commission_type

            if (this.state.commission_details.commission_type === "revenue_percentage") {
                postData['commission_rate'] = this.state.commission_details.commission_percentage
            }
            else if (this.state.commission_details.commission_type === "pay_per_use") {
                postData['commission_rate'] = this.state.commission_details.cost_per_use
                postData['currency'] = this.state.commission_details.currency
            }
        } else {
            postData['commission_rate'] = 0
        }

        this.props.doctoradd(postData, this.props.history);
    }

    render() {
        return <div>
            <Helmet>
                <title>Add Doctor</title>
            </Helmet>
            <Content title="Add Doctor">
                <Box>
                    <Box.Head title="Add Doctor Form"><a href="/doctors" className="btn btn-info pull-right">Back</a></Box.Head>
                    <Box.Body>
                        <form onSubmit={this.handleSubmit} onReset={this.resetForm}>
                            <h4>Personal Details</h4>
                            <hr />

                            <div className="row form-group">
                                <div className="col-xs-3">
                                    <ControlLabel>Name Title: <span>*</span></ControlLabel>
                                    <FormControl name="name_title" componentClass="select" value={this.state.name_title} onChange={this.handleChange} placeholder="Select Degree">
                                        <option value="" defaultValue>Select Title</option>
                                        <option value="Dr.">Dr.</option>
                                    </FormControl>
                                    {this.validator.message('name title', this.state.name_title, 'required')}
                                </div>

                                <div className="col-xs-4">
                                    <ControlLabel>First Name: <span>*</span></ControlLabel>
                                    <FormControl
                                        name='first_name'
                                        type="text"
                                        value={this.state.first_name}
                                        placeholder="Enter your first name"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('first_name', this.state.first_name, ['required', 'string', { regex: HELPER.personNameRegex() }])}
                                </div>

                                <div className="col-xs-4">
                                    <ControlLabel>Last Name: <span>*</span></ControlLabel>
                                    <FormControl
                                        name='last_name'
                                        type="text"
                                        value={this.state.last_name}
                                        placeholder="Enter your last name"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('last_name', this.state.last_name, ['required', 'string', { regex: HELPER.personNameRegex() }])}
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-xs-5">
                                    <ControlLabel>Email: <span>*</span></ControlLabel>
                                    <FormControl
                                        name='email'
                                        type="email"
                                        value={this.state.email}
                                        placeholder="Enter your email"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('email', this.state.email, 'required|email')}
                                </div>

                                <div className="col-xs-3">
                                    <ControlLabel>Contact Number:</ControlLabel>
                                    <FormControl
                                        name='contact_number'
                                        type="number"
                                        value={this.state.contact_number}
                                        placeholder="Enter your contact number"
                                        onChange={this.handleChange}
                                    />
                                    {this.validator.message('contact_number', this.state.contact_number, 'phone')}
                                </div>

                                <div className="col-xs-3">
                                    <ControlLabel>Alternate Number/Landline Number:</ControlLabel>
                                    <FormControl
                                        name='alt_contact_number'
                                        type="number"
                                        value={this.state.alt_contact_number}
                                        placeholder="Enter your Alternate Number/Landline Number"
                                        onChange={this.handleChange}
                                    />
                                    {this.validator.message('alternate_number', this.state.alt_contact_number, 'phone')}
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-xs-3">
                                    <ControlLabel>Gender: <span>* </span></ControlLabel>
                                    <div className="radio">
                                        <Radio name='gender' value='male' checked={this.state.gender === 'male'} onChange={this.handleRadioChange} inline> Male </Radio>
                                        <Radio name='gender' value='female' checked={this.state.gender === 'female'} onChange={this.handleRadioChange} inline> Female </Radio>
                                        <Radio name='gender' value='other' checked={this.state.gender === 'other'} onChange={this.handleRadioChange} inline> Other </Radio>
                                    </div>
                                </div>

                                <div className="col-xs-4">
                                    <ControlLabel>Date of Birth:</ControlLabel>
                                    <FormControl
                                        name='date_of_birth'
                                        type="date"
                                        value={this.state.date_of_birth}
                                        onChange={this.handleChange}
                                        min='1800-01-01'
                                        max="2999-12-31"
                                    />
                                    {this.validator.message('date_of_birth', this.state.date_of_birth, 'birthdate:' + new Date())}
                                </div>

                                <div className="col-xs-4">
                                    <ControlLabel>Registration Number: *</ControlLabel>
                                    <FormControl
                                        name='license_number'
                                        type="text"
                                        value={this.state.license_number}
                                        placeholder="Enter your Registration Number"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('registration_number', this.state.license_number, 'required')}
                                </div>
                            </div>

                            <h4>Professional Details</h4>
                            <hr />

                            <div className="row form-group">
                                <div className="col-xs-5">
                                    <ControlLabel>Select Degree: <span>*</span></ControlLabel>
                                    <FormControl name="degree" componentClass="select" value={this.state.other_degree ? "other_degree" : this.state.degree} onChange={this.handleChange} placeholder="Select Degree">
                                        <option value="">Select Degree</option>
                                        {CONFIG.DOCTORDEGREE.map((value) => {
                                            return <option value={value}>{value}</option>
                                        })}
                                        <option value="other_degree" >Other</option>
                                    </FormControl>
                                    {this.validator.message('degree', this.state.degree, 'required')}
                                </div>

                                <div className="col-xs-6">
                                    <ControlLabel>Select Specialization:</ControlLabel>
                                    <FormControl name='specialization' componentClass="select" value={this.state.other_specialization ? "other_specialization" : this.state.specialization} onChange={this.handleChange} placeholder="Select specialization">
                                        <option value="">Select Specialization</option>
                                        {CONFIG.SPECIALIZATION.map((value) => {
                                            return <option value={value}>{value}</option>
                                        })}
                                        <option value="other_specialization">Other</option>
                                    </FormControl>
                                    {this.validator.message('specialization', this.state.specialization, 'required')}
                                </div>
                            </div>
                            <div className="row form-group">
                                {this.state.other_degree &&
                                    <div className="col-xs-5">
                                        <FormControl
                                            name='other_degree_name'
                                            type="text"
                                            placeholder="Enter degree name"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                            value={this.state.other_degree_name}
                                        />
                                        {this.validator.message('degree_name', this.state.other_degree_name, 'required')}
                                    </div>
                                }
                                {!this.state.other_degree && <div className="col-xs-5"></div>}
                                {this.state.other_specialization &&
                                    <div className="col-xs-6">
                                        <FormControl
                                            name='other_specialization_name'
                                            type="text"
                                            placeholder="Enter specialization name"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                            value={this.state.other_specialization_name}
                                        />
                                        {this.validator.message('specialization_name', this.state.other_specialization_name, 'required')}
                                    </div>
                                }
                            </div>

                            <h4>Billing Details</h4>
                            <hr />

                            <div className="row form-group">
                                <div className="col-xs-5">
                                    <ControlLabel>TRN Number: </ControlLabel>
                                    <FormControl
                                        name='trn_number'
                                        type="text"
                                        value={this.state.trn_number}
                                        placeholder="Enter your TRN number"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.state.is_trn_applicable ? this.validator.message('trn_number', this.state.trn_number, 'required|max:15,string') : ''}
                                </div>

                                <div className="col-xs-6">
                                    <ControlLabel>Bill Name: <span>*</span></ControlLabel>
                                    <FormControl
                                        name='billing_name'
                                        type="text"
                                        value={this.state.billing_name}
                                        placeholder="Enter your bill name"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('billing_name', this.state.billing_name, 'required|string|max:100,string')}
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-xs-4">
                                    <ControlLabel>Address Line 1: *</ControlLabel>
                                    <FormControl
                                        name='address_line_1'
                                        type="text"
                                        value={this.state.address_line_1}
                                        placeholder="Enter your address line"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('address_line_1', this.state.address_line_1, 'required|string|max:255,string')}
                                </div>

                                <div className="col-xs-4">
                                    <ControlLabel>Address Line 2:</ControlLabel>
                                    <FormControl
                                        name='address_line_2'
                                        type="text"
                                        value={this.state.address_line_2}
                                        placeholder="Enter your address line"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('address_line_2', this.state.address_line_2, 'string|max:255,string')}
                                </div>

                                <div className="col-xs-3">
                                    <ControlLabel>Address Line 3:</ControlLabel>
                                    <FormControl
                                        name='address_line_3'
                                        type="text"
                                        value={this.state.address_line_3}
                                        placeholder="Enter your address line"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('address_line_3', this.state.address_line_3, 'string|max:255,string')}
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-xs-3">
                                    <ControlLabel>Parish: *</ControlLabel>
                                    <FormControl name="parish" componentClass="select" value={this.state.parish} onChange={this.handleChange} placeholder="Select Parish">
                                        <option value=''>Select Parish</option>
                                        {CONFIG.PARISH.map((value) => {
                                            return <option value={value}>{value}</option>
                                        })}
                                    </FormControl>
                                    {this.validator.message('parish', this.state.parish, 'required')}
                                </div>

                                <div className="col-xs-4">
                                    <ControlLabel>City: *</ControlLabel>
                                    <FormControl
                                        name='city'
                                        type="text"
                                        value={this.state.city}
                                        placeholder="Enter your city"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('city', this.state.city, 'required|string|max:100,string')}
                                </div>

                                <div className="col-xs-4">
                                    <ControlLabel>Zip Code: </ControlLabel>
                                    <FormControl
                                        name='pincode'
                                        type="text"
                                        value={this.state.pincode}
                                        placeholder="Enter your Zip Code"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('pincode', this.state.pincode, 'alpha_num|max:7')}
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-xs-3">
                                    <ControlLabel>Is TRN applicable: &nbsp;&nbsp;</ControlLabel>
                                    <input type="checkbox" id='is_trn_applicable' name="is_trn_applicable" value={this.state.is_trn_applicable} onChange={this.handleChange} />
                                    <label htmlFor='is_trn_applicable'>&nbsp;&nbsp;Yes</label>
                                </div>
                            </div>

                            {HELPER.hasRole(this.props.admin, ['super-admin', 'admin', 'business-development-head']) && (<>
                                <h4>Commission Details</h4>
                                <hr />
                                {this.state.commission_details && <AddCommission
                                    commission_details={this.state.commission_details}
                                    handleCommissionChange={this.handleCommissionChange}
                                    validator={this.validator}
                                />}
                            </>)}

                            <h4>Location Details</h4>
                            <hr />
                            {this.state.location_details && <AddMultiLocation
                                location_details={this.state.location_details}
                                handleLocationChange={this.handleLocationChange}
                                validator={this.validator}
                                addMoreLocationBlock={this.addMoreLocationBlock}
                                removeLocationBlock={this.removeLocationBlock}
                            />}

                            <FormGroup className="row" controlId="">
                                <Col sm={4} md={4}>
                                    <ButtonToolbar>
                                        <Button bsStyle="primary" type='submit'>Submit</Button>
                                        <button type="reset" className="btn btn-btn btn-danger"><i className="fa fa-repeat"></i> Reset</button>
                                    </ButtonToolbar>
                                </Col>
                            </FormGroup>
                        </form>
                        <Alert />
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    doctor: state.doctor,
    admin: state.auth.admin
});

const mapActionsToProps = ({
    doctoradd: DoctorAction.doctoradd,
    navaction: Authaction.navaction
})

export default connect(mapStateToProps, mapActionsToProps)(AddDoctorPage);
