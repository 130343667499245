import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class DrugService {
    static list(page, sizePerPage, sortBy, searchText) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText)
        };

        return axios.get(apiRoute('/v1/drugs'), requestOptions);
    }

    static otherDrugList({ page, sizePerPage, sortBy, searchText }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText)
        };

        return axios.get(apiRoute('/v1/other-drugs'), requestOptions);
    }

    static drugadd(postData) {
        let paramsdata = JSON.stringify(postData);

        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute('/v1/drugs'), paramsdata, requestOptions);
    }

    static fetchdrug(id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/drugs/' + id), requestOptions);
    }

    static deletedrug(id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.delete(apiRoute('/v1/drugs/' + id), requestOptions);
    }

    static drugupdate(postData) {
        let paramsdata = JSON.stringify(postData);

        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.patch(apiRoute('/v1/drugs/' + postData.id), paramsdata, requestOptions);
    }

    static exportDrugMaster() {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/drug/exports/csv'), requestOptions);
    }
}

export default DrugService;
