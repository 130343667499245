import React from 'react';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import { Table, Button, FormControl, Col } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import * as TrialAccountActions from "../../actions/trialAccountActions";
import { Box, Content } from '../../components/Elements';
import _ from 'lodash';
import { getDateFormat } from '../../utils/helpers';
import { Link } from 'react-router-dom';
import moment from 'moment';
import TrialAccountService from '../../services/trialAccountService';
import Notification from '../../utils/notification';
import * as HELPER from '../../utils/helpers';

class ViewTrialAccountPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            reload: false,
            flag: true,
            expiry_date: ''
        };

        const { match: { params } } = this.props;
        const id = params.id;
        this.props.fetchTrialAccount(id);
        this.checkTrailExpiry = this.checkTrailExpiry.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps = (props, state) => {
        if (this.state.flag === true) {
            this.setState({
                expiry_date: moment(props.trial_account.trial_expiry_at).format('YYYY-MM-DD')
            });
        }
    }

    checkTrailExpiry = (trialExpiryDate) => {
        const currentDate = moment();
        const expiryDate = moment(trialExpiryDate);
        const remainingTime = moment(expiryDate).diff(moment(currentDate), 'minutes');

        if (remainingTime <= 0) {
            return true;
        }

        return false;
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    extendExpiryDate = (serviceProviderId, expiryDate) => {
        if (new Date(expiryDate) < new Date()) {
            Notification.show("error", { message: "Past dates are not acceptable" });
            return false;
        }

        let postData = {
            service_provider_id: serviceProviderId,
            expiry_date: moment(new Date(expiryDate)).endOf("day").format('YYYY-MM-DD HH:mm:ss')
        }
        TrialAccountService.extendTrialAccountExpiry(postData)
            .then(resp => {
                let data = resp.data;
                Notification.show('success', { message: data.message });
                this.setState({
                    reload: true
                });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    Notification.show('error', { message: errorData.message });
                }
            });
    }

    render() {

        const { trial_account } = this.props;

        let service_provider;
        let user;
        let location;

        if (trial_account && trial_account.service_provider_id) {

            service_provider = <Table responsive striped>
                <tbody>
                    <tr><th colspan="6"><h4>Service Provider Info:</h4></th></tr>
                    <tr>
                        <th>Service Provider Name: </th>
                        <td>{_.capitalize(trial_account.name)}</td>
                        <th>Service Provider Type:</th>
                        <td>{_.capitalize(trial_account.service_provider_type)}</td>
                    </tr>
                    <tr>
                        <th>Trial Expiry At</th>
                        <td>{getDateFormat(trial_account.trial_expiry_at)}</td>
                    </tr>
                </tbody>
            </Table>;

            user = <Table responsive striped>
                <tbody>
                    <tr><th colspan="6"><h4>Service Provider User Info:</h4></th></tr>
                    <tr>
                        <th>User Name:</th>
                        <td>{trial_account.user.user.full_name}</td>
                        <th>Email:</th>
                        <td>{trial_account.user.user.email}</td>
                    </tr>
                    <tr>
                        <th>Contact Number:</th>
                        <td>{trial_account.user.user.contact_number ? trial_account.user.user.contact_number : "--"}</td>
                    </tr>
                </tbody>
            </Table>;

            location = <Table responsive striped>
                <tbody>
                    <tr><th><h4>Service Provider Location Info:</h4></th></tr>
                    <tr>
                        <th>Location Name</th>
                        <td>{trial_account.location.name}</td>
                    </tr>
                    <tr>
                        <th>Address Line 1</th>
                        <td>{trial_account.location.address.address_line_1}</td>
                    </tr>
                    <tr>
                        <th>Address Line 2</th>
                        <td>{trial_account.location.address.address_line_2 ? trial_account.location.address.address_line_2 : "--"}</td>
                    </tr>
                    <tr>
                        <th>Address Line 3</th>
                        <td>{trial_account.location.address.address_line_3 ? trial_account.location.address.address_line_3 : "--"}</td>
                    </tr>
                    <tr>
                        <th>Parish</th>
                        <td>{trial_account.location.address.parish}</td>
                    </tr>
                    <tr>
                        <th>City</th>
                        <td>{trial_account.location.address.city}</td>
                    </tr>
                    <tr>
                        <th>Zip Code</th>
                        <td>{trial_account.location.address.pincode}</td>
                    </tr>
                </tbody>
            </Table>;
        }

        return <div>
            <Helmet>
                <title>View Trial Account</title>
            </Helmet>
            <Content title="Trial Account">
                {HELPER.hasPermission(this.props.admin, ['trial-account-extend']) && <Box>
                    <Box.Head title="Extend Expiry Date"></Box.Head>
                    <Box.Body>
                        <Col sm={3}>
                            <FormControl
                                name='expiry_date'
                                type="date"
                                value={this.state.expiry_date}
                                onChange={this.handleChange}
                                min={new Date(Date.now()).toISOString().slice(0, 10)}
                                max="2999-12-31"
                            />
                        </Col>
                        <Col sm={1}>
                            <Button bsStyle="success" onClick={() => this.extendExpiryDate(trial_account.service_provider_id, this.state.expiry_date)}>Extend Trial Account</Button>
                        </Col>
                    </Box.Body>
                </Box>}

                <Box>
                    <Box.Head title="Trial Account Details"><Link to="/trial/accounts" className="btn btn-info pull-right">Back</Link></Box.Head>
                    <div>
                        <div>
                            {service_provider}
                        </div>
                        <div>
                            {user}
                        </div>
                        <div>
                            {location}
                        </div>
                    </div>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    trial_account: state.trial_account
});

const mapActionsToProps = ({
    fetchTrialAccount: TrialAccountActions.fetchTrialAccount
});

export default connect(mapStateToProps, mapActionsToProps)(ViewTrialAccountPage);