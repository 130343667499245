import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { FormGroup, ControlLabel, FormControl, Col, Button } from 'react-bootstrap';
import * as DrugAction from '../../actions/drugActions';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';
import Alert from '../../components/Alert';
import Select from 'react-select';
import axios from 'axios';
import { apiRoute, getApiHeader } from '../../utils/helpers';
import NDCBlock from '../../components/NDCBlock';

class AddDrugPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            generic_name: '',
            dosage_form: '',
            strength: '',
            uom: '',
            ndc: [{
                ndc_code: '',
                package_size: '',
                min_sell_quantity: '',
                manufacturer_name: '',
                price: '',
                drug_coverage: []
            }],
            generic_drug_data: [],
            drug_dosage_form: '',
            status: ''
        };

        this.validator = new SimpleReactValidator();

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        } else {

            const postData = {
                name: this.state.name,
                generic_drug_id: this.state.generic_name.value,
                dosage_form: this.state.dosage_form,
                strength: this.state.strength,
                unit_of_measurement: this.state.uom,
                drug_skus: this.state.ndc,
                status: this.state.status
            };
            this.props.drugadd(postData, this.props.history);
        }
    }

    get_all_generic_drugs() {
        const requestOptions = {
            headers: getApiHeader(),
            params: {
                page: 1,
                limit: 2000,
                sort: '',
                search: ''
            }
        };

        return axios.get(apiRoute('/v1/generic-drugs'), requestOptions);

    }

    get_dosage_forms() {
        const requestOptions = {
            headers: getApiHeader(null, true),
        };

        return axios.get(apiRoute('/v1/drugs/dosage-forms'), requestOptions);
    }

    componentDidMount() {
        this.get_all_generic_drugs().then(res => {
            let generic_data = res.data.data;
            let generic_drug_data = [];

            generic_data.forEach(e => {
                generic_drug_data.push({ value: e.id, label: e.name });
            });

            this.setState({
                generic_drug_data: generic_drug_data
            });

        });

        this.get_dosage_forms().then(res => {
            this.setState({
                drug_dosage_form: res.data
            });
        });
    }

    handleSelectChange = (e) => {
        this.setState({
            generic_name: e
        });
    }

    handleAddNDC = () => {
        this.setState({
            ndc: this.state.ndc.concat([{
                ndc_code: '',
                package_size: '',
                minimum_sale_qnt: '',
                manufacturer_name: '',
                price: '',
                drug_coverage: []
            }]),
        });
    };

    handleRemoveNDC = (event) => {
        const idx = Number(event.target.dataset["id"]);
        const ndc = this.state.ndc.filter((s, sidx) => idx !== sidx);
        this.setState({ ndc: ndc });
    };

    handleNDCChange = (idx, data) => {
        const newNDC = this.state.ndc.map((ndc, sidx) => {
            if (idx !== sidx) return ndc;

            return { ...ndc, ...data };
        });

        this.setState({ ndc: newNDC });

    };

    handleDrugCoverageChange = (idx, data) => {
        let drug_covr_data = this.state.ndc;
        drug_covr_data[idx].drug_coverage = data;

        this.setState({ ndc: drug_covr_data });
    };

    render() {
        return <div>
            <Helmet>
                <title>Add Drug</title>
            </Helmet>
            <Content title="Drugs">
                <Box>
                    <Box.Head title="Add Drug" backTo="/drugs"></Box.Head>
                    <Box.Body>
                        <Alert />
                        <form onSubmit={this.handleSubmit}>

                            <FormGroup className="row" controlId="">
                                <Col sm={3}>
                                    <ControlLabel>Generic Name: <span>*</span></ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <Select
                                        value={this.state.generic_name}
                                        onChange={this.handleSelectChange}
                                        options={this.state.generic_drug_data}
                                    />
                                    {this.validator.message('generic_name', this.state.generic_name, 'required')}
                                </Col>
                            </FormGroup>

                            <FormGroup className="row" controlId="">
                                <Col sm={3}>
                                    <ControlLabel>Name: <span>*</span></ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <FormControl
                                        name='name'
                                        type="text"
                                        value={this.state.name}
                                        placeholder="Enter drug name"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('name', this.state.name, 'required|max:100,string')}
                                </Col>
                            </FormGroup>

                            <FormGroup className="row" controlId="">
                                <Col sm={3}>
                                    <ControlLabel>Dosage From: <span>*</span></ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <FormControl name="dosage_form" componentClass="select" value={this.state.dosage_form} onChange={this.handleChange}>
                                        <option value="">Select dosage form</option>
                                        {this.state.drug_dosage_form && this.state.drug_dosage_form.map((index) => (
                                            <option value={index.key}>{index.label}</option>
                                        ))}
                                    </FormControl>
                                    {this.validator.message('dosage_form', this.state.dosage_form, 'required')}
                                </Col>
                            </FormGroup>

                            <FormGroup className="row" controlId="">
                                <Col sm={3}>
                                    <ControlLabel>Strength: <span>*</span></ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <FormControl
                                        name='strength'
                                        type="text"
                                        value={this.state.strength}
                                        placeholder="Enter drug strength"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('strength', this.state.strength, 'required|string')}
                                </Col>
                            </FormGroup>

                            <FormGroup className="row" controlId="">
                                <Col sm={3}>
                                    <ControlLabel>Unit Of Measurement: <span>*</span></ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <FormControl name="uom" componentClass="select" value={this.state.uom} onChange={this.handleChange}>
                                        <option value="">Select measurement</option>
                                        <option value="ml">ml</option>
                                        <option value="mg">mg</option>
                                    </FormControl>
                                    {/* {this.validator.message('uom', this.state.dosage_form, 'required')} */}
                                </Col>
                            </FormGroup>

                            <FormGroup className="row" controlId="">
                                <Col sm={3}>
                                    <ControlLabel>Status: <span>*</span></ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <FormControl name="status" componentClass="select" value={this.state.status} onChange={this.handleChange}>
                                        <option value="">Select Status</option>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </FormControl>
                                    {this.validator.message('status', this.state.status, 'required')}
                                </Col>
                            </FormGroup>

                            <FormGroup className="row">
                                <Col sm={1}><ControlLabel>Drug SKUs: </ControlLabel></Col>
                                <Col sm={11}>
                                    {this.state.ndc.map((ndc, idx) => (
                                        <NDCBlock
                                            ndc={ndc}
                                            idx={idx}
                                            validator={this.validator}
                                            handleChangeNDC={this.handleNDCChange}
                                            handleRemoveNDC={this.handleRemoveNDC}
                                            handleDrugCoverageChange={this.handleDrugCoverageChange}
                                        />
                                    ))}
                                    <Button onClick={this.handleAddNDC} bsStyle="primary" className="pull-right"><span className="fa fa-plus"></span> Add Sku</Button>
                                </Col>
                            </FormGroup>

                            <FormGroup className="row" controlId="">
                                <Col sm={6} md={6}>
                                    <Button type='submit' bsStyle='success'>Save Drug</Button>
                                </Col>
                            </FormGroup>
                        </form>
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    drugs: state.drugs,
    generic_drugs: state.generic_drugs,
    admin: state.auth.admin
});

const mapActionsToProps = ({
    drugadd: DrugAction.drugadd
})

export default connect(mapStateToProps, mapActionsToProps)(AddDrugPage);
