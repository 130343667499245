import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { FormGroup, ControlLabel, FormControl, Col, Button, ButtonToolbar, Radio } from 'react-bootstrap';
import * as RadiologyAction from '../../actions/radiologyAction';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';
import * as CONFIG from "../../utils/config";
import Alert from '../../components/Alert';
import _ from "lodash";
import Notification from '../../utils/notification';
import * as HELPER from '../../utils/helpers';
import * as LoaderAction from '../../actions/loaderAction';
import EditMultiOwner from "../../components/Owner/EditMultiOwner";
import EditMultiLocation from "../../components/Location/EditMultiLocation";
import EditCommission from '../../components/Commission/EditCommission';

class EditRadiologistPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            service_provider_id: '',
            license_number: "",
            no_commission: "",
            commission_rate: "",
            commission_type: "",
            currency: "",
            address_line_1: "",
            address_line_2: "",
            address_line_3: "",
            city: "",
            pincode: "",
            parish: "",
            trn_checked: '',
            is_trn_applicable: false,
            trn_number: '',
            other_degree_name: '',
            other_specialization_name: '',
            location_details: '',
            flag: true,
            owners: ''
        };

        this.validator = new SimpleReactValidator({
            validators: {
                birthdate: {
                    message: 'The date of birth must be less than todays date',
                    rule: (val, params) => {
                        return (new Date(val) > new Date(params) ? false : true)
                    },
                    required: true
                }
            }
        });
        const { match: { params } } = this.props;
        const serviceProviderId = params.serviceProviderId;
        this.props.fetchradiology(serviceProviderId);
        this.handleChange = this.handleChange.bind(this);
        this.validateAndSubmit = this.validateAndSubmit.bind(this);
    }

    componentWillReceiveProps = (props, state) => {
        if (this.state.flag === true) {

            this.setState({
                service_provider_id: props.radiology.service_provider_id,
                name_title: props.radiology.name_title,
                first_name: props.radiology.first_name,
                last_name: props.radiology.last_name,
                email: props.radiology.email,
                contact_number: props.radiology.contact_number,
                alt_contact_number: props.radiology.alt_contact_number,
                gender: props.radiology.gender,
                date_of_birth: props.radiology.date_of_birth,
                license_number: props.radiology.license_number,
                no_commission: props.radiology.no_commission,
                commission_rate: props.radiology.commission_rate,
                commission_type: props.radiology.commission_type,
                currency: props.radiology.currency,
                billing_name: props.radiology.billing_name,
                trn_number: props.radiology.trn_number,
                is_trn_applicable: props.radiology.is_trn_applicable,
                trn_checked: props.radiology.is_trn_applicable === true ? "checked" : '',
                address_line_1: props.radiology.billing_address ? props.radiology.billing_address.address_line_1 : '',
                address_line_2: props.radiology.billing_address ? props.radiology.billing_address.address_line_2 : '',
                address_line_3: props.radiology.billing_address ? props.radiology.billing_address.address_line_3 : '',
                city: props.radiology.billing_address ? props.radiology.billing_address.city : '',
                pincode: props.radiology.billing_address ? props.radiology.billing_address.pincode : '',
                parish: props.radiology.billing_address ? props.radiology.billing_address.parish : '',
                location_details: props.radiology.locations,
                flag: false,
                owners: props.radiology.owners
            });
        } else {
            return state;
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        if (name === "is_trn_applicable") {
            if (event.target.checked) {
                this.setState({
                    is_trn_applicable: true,
                    trn_checked: 'checked'
                });
            } else {
                this.setState({
                    is_trn_applicable: false,
                    trn_checked: ''
                });
            }
        }
    }

    handleCommissionChange = (key, value) => {

        if (key === "no_commission") {
            value = !this.state.no_commission
        }
        this.setState({
            [key]: value
        }
        )
    }

    validateAndSubmit(e) {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        if (this.state.is_trn_applicable === true) {
            if (_.isEmpty(this.state.trn_number)) {
                Notification.show('error', { message: "Please enter TRN Number" });
                return false;
            }
        }

        if (HELPER.hasRole(this.props.admin, ['super-admin', 'admin', 'business-development-head']) && _.isEmpty(this.state.commission_type) && !this.state.no_commission) {
            Notification.show('error', { message: "Please fill Commission details" });
            return false;
        }

        let postData = {
            name_title: this.state.name_title,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            gender: this.state.gender,
            date_of_birth: this.state.date_of_birth,
            trn_number: this.state.trn_number,
            contact_number: this.state.contact_number,
            alt_contact_number: this.state.alt_contact_number,
            email: this.state.email,
            license_number: this.state.license_number,
            is_trn_applicable: this.state.is_trn_applicable,
            billing_address: {
                address_line_1: this.state.address_line_1,
                address_line_2: this.state.address_line_2,
                address_line_3: this.state.address_line_3,
                city: this.state.city,
                pincode: this.state.pincode,
                parish: this.state.parish
            },
            billing_name: this.state.billing_name,
            no_commission: this.state.no_commission,
        };

        if (this.state.no_commission === false) {
            postData['commission_type'] = this.state.commission_type
            postData['commission_rate'] = this.state.commission_rate

            if (this.state.commission_type === "pay_per_use") {
                postData['currency'] = this.state.currency
            }
        } else {
            postData['commission_rate'] = 0
        }

        this.props.radiologyupdate(this.state.service_provider_id, postData, this.props.history);
    }

    render() {
        const { alert } = this.props;
        return <div>
            <Helmet>
                <title>Edit Radiology</title>
            </Helmet>
            <Content title="Edit Radiology">
                <Box>
                    <Box.Head title="Edit Radiology Form"></Box.Head>
                    <Box.Body>
                        {alert && alert.message &&
                            <Alert bsStyle={alert.type}>{alert.message}</Alert>
                        }
                        {this.state && this.props.radiology.service_provider_id &&
                            <div>
                                <ul className="nav nav-tabs">
                                    <li className="active"><a data-toggle="tab" href="#basic_details"> Basic Details</a></li>
                                    <li className=""><a data-toggle="tab" href="#locations"> Location </a></li>
                                    <li className=""><a data-toggle="tab" href="#owners"> Owners Details </a></li>
                                </ul>
                                <div className="tab-content">
                                    <div id="basic_details" className="tab-pane fade in active">
                                        <form onSubmit={this.validateAndSubmit}>
                                            <h4>Billing Details</h4>
                                            <hr />

                                            <div className="row form-group">
                                                <div className="col-xs-5">
                                                    <ControlLabel>TRN Number: </ControlLabel>
                                                    <FormControl
                                                        name='trn_number'
                                                        type="text"
                                                        value={this.state.trn_number}
                                                        placeholder="Enter your TRN number"
                                                        onChange={this.handleChange}
                                                        autoComplete="none"
                                                    />
                                                    {this.state.is_trn_applicable ? this.validator.message('trn_number', this.state.trn_number, 'required|max:15,string') : ''}
                                                </div>

                                                <div className="col-xs-6">
                                                    <ControlLabel>Bill Name: <span>*</span></ControlLabel>
                                                    <FormControl
                                                        name='billing_name'
                                                        type="text"
                                                        value={this.state.billing_name}
                                                        placeholder="Enter your bill name"
                                                        onChange={this.handleChange}
                                                        autoComplete="none"
                                                    />
                                                    {this.validator.message('billing_name', this.state.billing_name, 'required|string|max:100,string')}
                                                </div>
                                            </div>

                                            <div className="row form-group">
                                                <div className="col-xs-4">
                                                    <ControlLabel>Address Line 1: *</ControlLabel>
                                                    <FormControl
                                                        name='address_line_1'
                                                        type="text"
                                                        value={this.state.address_line_1}
                                                        placeholder="Enter your address line"
                                                        onChange={this.handleChange}
                                                        autoComplete="none"
                                                    />
                                                    {this.validator.message('address_line_1', this.state.address_line_1, 'required|string|max:255,string')}
                                                </div>

                                                <div className="col-xs-4">
                                                    <ControlLabel>Address Line 2:</ControlLabel>
                                                    <FormControl
                                                        name='address_line_2'
                                                        type="text"
                                                        value={this.state.address_line_2}
                                                        placeholder="Enter your address line"
                                                        onChange={this.handleChange}
                                                        autoComplete="none"
                                                    />
                                                    {this.validator.message('address_line_2', this.state.address_line_2, 'string|max:255,string')}
                                                </div>

                                                <div className="col-xs-3">
                                                    <ControlLabel>Address Line 3:</ControlLabel>
                                                    <FormControl
                                                        name='address_line_3'
                                                        type="text"
                                                        value={this.state.address_line_3}
                                                        placeholder="Enter your address line"
                                                        onChange={this.handleChange}
                                                        autoComplete="none"
                                                    />
                                                    {this.validator.message('address_line_3', this.state.address_line_3, 'string|max:255,string')}
                                                </div>
                                            </div>

                                            <div className="row form-group">
                                                <div className="col-xs-3">
                                                    <ControlLabel>Parish: *</ControlLabel>
                                                    <FormControl name="parish" componentClass="select" value={this.state.parish} onChange={this.handleChange} placeholder="Select Parish">
                                                        <option value=''>Select Parish</option>
                                                        {CONFIG.PARISH.map((value) => {
                                                            return <option value={value}>{value}</option>
                                                        })}
                                                    </FormControl>
                                                    {this.validator.message('parish', this.state.parish, 'required')}
                                                </div>

                                                <div className="col-xs-4">
                                                    <ControlLabel>City: *</ControlLabel>
                                                    <FormControl
                                                        name='city'
                                                        type="text"
                                                        value={this.state.city}
                                                        placeholder="Enter your city"
                                                        onChange={this.handleChange}
                                                        autoComplete="none"
                                                    />
                                                    {this.validator.message('city', this.state.city, 'required|string|max:100,string')}
                                                </div>

                                                <div className="col-xs-4">
                                                    <ControlLabel>Zip Code: </ControlLabel>
                                                    <FormControl
                                                        name='pincode'
                                                        type="text"
                                                        value={this.state.pincode}
                                                        placeholder="Enter your Zip Code"
                                                        onChange={this.handleChange}
                                                        autoComplete="none"
                                                    />
                                                    {this.validator.message('pincode', this.state.pincode, 'alpha_num|max:7')}
                                                </div>
                                            </div>

                                            <div className="row form-group">
                                                <div className="col-xs-3">
                                                    <ControlLabel>Is TRN applicable: &nbsp;&nbsp;</ControlLabel>
                                                    <input type="checkbox" id='is_trn_applicable' name="is_trn_applicable" value={this.state.is_trn_applicable} onChange={this.handleChange} checked={this.state.trn_checked} />
                                                    <label for='is_trn_applicable'>&nbsp;&nbsp;Yes</label>
                                                </div>

                                                <div className="col-xs-4">
                                                    <ControlLabel>Registration Number: *</ControlLabel>
                                                    <FormControl
                                                        name='license_number'
                                                        type="text"
                                                        value={this.state.license_number}
                                                        placeholder="Enter your Registration Number"
                                                        onChange={this.handleChange}
                                                        autoComplete="none"
                                                    />
                                                    {this.validator.message('registration_number', this.state.license_number, 'required')}
                                                </div>
                                            </div>

                                            {HELPER.hasRole(this.props.admin, ['super-admin', 'admin', 'business-development-head']) && (<>
                                                <h4>Commission Details</h4>
                                                <hr />
                                                <EditCommission
                                                    no_commission={this.state.no_commission}
                                                    commission_type={this.state.commission_type}
                                                    commission_rate={this.state.commission_rate}
                                                    currency={this.state.currency}
                                                    handleCommissionChange={this.handleCommissionChange}
                                                    validator={this.validator}
                                                />
                                            </>)}

                                            <FormGroup className="row" controlId="">
                                                <Col sm={4} md={4}>
                                                    <ButtonToolbar>
                                                        <Button bsStyle="primary" type='submit'>Submit</Button>
                                                        <button type="reset" className="btn btn-btn btn-danger"><i className="fa fa-repeat"></i> Reset</button>
                                                    </ButtonToolbar>
                                                </Col>
                                            </FormGroup>
                                        </form>
                                    </div>
                                    <div id="locations" className="tab-pane fade">
                                        <h4>Location Details</h4>
                                        <hr />
                                        {this.state.location_details && <EditMultiLocation
                                            location_details={this.state.location_details}
                                            history={this.props.history}
                                            type='radiologies'
                                            service_provider_id={this.state.service_provider_id} />}
                                    </div>
                                    <div id="owners" className="tab-pane fade">
                                        <h4>Owners Details</h4>
                                        <hr />
                                        {this.state.owners && <EditMultiOwner
                                            serviceProviderId={this.state.service_provider_id}
                                            owners={this.state.owners}
                                            type='radiologies'
                                            showLoader={this.props.showLoader}
                                            hideLoader={this.props.hideLoader}
                                            history={this.props.history} />}
                                    </div>
                                </div>
                            </div>}
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    radiology: state.radiology,
    alert: state.alert,
    admin: state.auth.admin
});

const mapActionsToProps = ({
    fetchradiology: RadiologyAction.fetchradiology,
    radiologyupdate: RadiologyAction.radiologyupdate,
    showLoader: LoaderAction.loaderShow,
    hideLoader: LoaderAction.loaderHide,
});

export default connect(mapStateToProps, mapActionsToProps)(EditRadiologistPage);
