import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { FormGroup, ControlLabel, FormControl, Col, Button } from 'react-bootstrap';
import * as DrugAction from '../../actions/drugActions';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';
import Alert from '../../components/Alert';
import _ from 'lodash';
import Select from 'react-select';
import axios from 'axios';
import { apiRoute, getApiHeader } from '../../utils/helpers';
import NDCBlock from '../../components/NDCBlock';

class EditDrugPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: '',
            name: '',
            generic_drug_id: '',
            generic_drug: [],
            generic_name: '',
            dosage_form: '',
            strength: '',
            unit_of_measurement: '',
            ndc: [],
            generic_drug_data: [],
            generic_name_err: '',
            flag: 'null',
            drug_dosage_form: '',
            status: ''
        };

        const { match: { params } } = this.props;
        const id = params.id;

        this.props.fetchDrug(id);

        this.validator = new SimpleReactValidator();

        this.onChange = this.onChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillReceiveProps(props) {
        const drugs = props.drugs;

        this.setState({
            id: drugs ? drugs.id : '',
            name: drugs ? drugs.name : '',
            generic_drug_id: drugs ? drugs.generic_drug_id : '',
            generic_drug: drugs && (!_.isEmpty(drugs.generic_drug)) ? { value: drugs.generic_drug.id, label: drugs.generic_drug.name } : {},
            dosage_form: drugs && drugs.dosage_form ? drugs.dosage_form.key : '',
            strength: drugs ? drugs.strength : '',
            unit_of_measurement: drugs ? drugs.unit_of_measurement : '',
            ndc: drugs && drugs.drug_skus ? (drugs.drug_skus).map((value) => {
                return {
                    'id': value.id,
                    'drug_id': value.drug_id,
                    'manufacturer_name': value.manufacturer_name,
                    'min_sell_quantity': value.min_sell_quantity,
                    'ndc_code': value.ndc_code,
                    'package_size': value.package_size,
                    'price': '',
                    'drug_coverage': value.drug_coverages.map((value) => {
                        return {
                            value: value,
                            label: _.toUpper(value)
                        }
                    })
                }
            }) : [],
            status: drugs.status
        });

    }

    onChange(e) {
        const name = e.target.name
        const value = e.target.value
        this.setState({ [name]: value })
    }


    validateAndSubmit(e) {
        e.preventDefault();

    }

    get_all_generic_drugs() {
        const requestOptions = {
            headers: getApiHeader(),
            params: {
                page: 1,
                limit: 500,
                sort: '',
                search: ''
            }
        };
        return axios.get(apiRoute('/v1/generic-drugs'), requestOptions);
    }

    get_dosage_forms() {
        const requestOptions = {
            headers: getApiHeader(null, true),
        };

        return axios.get(apiRoute('/v1/drugs/dosage-forms'), requestOptions);
    }

    componentDidMount() {
        this.get_all_generic_drugs().then(res => {
            let generic_data = res.data.data;
            let generic_drug_data = [];

            generic_data.forEach(e => {
                generic_drug_data.push({ value: e.id, label: e.name });
            });
            this.setState({
                generic_drug_data: generic_drug_data
            });

        });

        this.get_dosage_forms().then(res => {
            this.setState({
                drug_dosage_form: res.data
            });
        });
    }

    handleSelectChange = (e) => {
        this.setState({
            generic_drug: e
        });

    }

    handleAddNDC = () => {
        this.setState({
            ndc: this.state.ndc.concat([{
                ndc_code: '',
                package_size: '',
                min_sell_quantity: '',
                manufacturer_name: '',
                price: ''
            }])
        });
    };

    handleRemoveNDC = (event) => {
        const idx = Number(event.target.dataset["id"]);
        const ndc = this.state.ndc.filter((s, sidx) => idx !== sidx);
        this.setState({ ndc: ndc });
    };

    handleNDCChange = (idx, data) => {
        const newNDC = this.state.ndc.map((ndc, sidx) => {
            if (idx !== sidx) return ndc;

            return { ...ndc, ...data };
        });

        this.setState({ ndc: newNDC });

    };

    handleSubmit(e) {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        } else {
            this.setState({
                generic_name_err: ''
            });

            const postData = {
                id: this.state.id,
                name: this.state.name,
                generic_drug_id: this.state.generic_drug.value,
                dosage_form: this.state.dosage_form,
                strength: this.state.strength,
                unit_of_measurement: this.state.unit_of_measurement,
                drug_skus: this.state.ndc,
                status: this.state.status
            };
            this.props.drugupdate(postData, this.props.history);
        }
    }

    handleDrugCoverageChange = (idx, data) => {
        let drug_covr_data = this.state.ndc;
        drug_covr_data[idx].drug_coverage = data;

        this.setState({ ndc: drug_covr_data });
    };

    render() {
        return <div>
            <Helmet>
                <title>Edit Drug</title>
            </Helmet>
            <Content title="Drugs">

                <Box>
                    <Box.Head title="Edit Drug" backTo="/drugs"></Box.Head>
                    <Box.Body>
                        <Alert />
                        {this.state && this.state.id &&
                            <form onSubmit={this.handleSubmit}>
                                <FormGroup className="row" controlId="">
                                    <Col sm={3}>
                                        <ControlLabel>Generic Name: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <Select
                                            value={this.state.generic_drug}
                                            onChange={this.handleSelectChange}
                                            options={this.state.generic_drug_data}
                                        />
                                        {this.state.generic_name_err &&
                                            <div className="srv-validation-message">{this.state.generic_name_err}</div>
                                        }
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={3}>
                                        <ControlLabel>Name: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='name'
                                            type="text"
                                            value={this.state.name}
                                            placeholder="Enter drug name"
                                            onChange={this.onChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('name', this.state.name, 'required|string')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row">
                                    <Col sm={3}>
                                        <ControlLabel>Dosage From: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl name='dosage_form' componentClass="select" value={this.state.dosage_form} onChange={this.onChange}>
                                            {this.state.drug_dosage_form && this.state.drug_dosage_form.map((index) => (
                                                <option value={index.key}>{index.label}</option>
                                            ))}
                                        </FormControl>
                                        {this.validator.message('dosage_form', this.state.dosage_form, 'required')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={3}>
                                        <ControlLabel>Strength: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='strength'
                                            type="text"
                                            value={this.state.strength}
                                            placeholder="Enter drug strength"
                                            onChange={this.onChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('strength', this.state.strength, 'required|string')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={3}>
                                        <ControlLabel>Unit Of Measurement: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl name="unit_of_measurement" componentClass="select" value={this.state.unit_of_measurement} onChange={this.onChange}>
                                            <option value="">Select measurement</option>
                                            <option value="ml">ml</option>
                                            <option value="mg">mg</option>
                                        </FormControl>
                                        {/* {this.validator.message('unit_of_measurement', this.state.unit_of_measurement, 'required')} */}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={3}>
                                        <ControlLabel>Status: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl name="status" componentClass="select" value={this.state.status} onChange={this.onChange}>
                                            <option value="">Select Status</option>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </FormControl>
                                        {this.validator.message('status', this.state.status, 'required')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row">
                                    <Col sm={2}><ControlLabel>Drug SKUs: </ControlLabel></Col>
                                    <Col sm={10}>
                                        {this.state.ndc.map((ndc, idx) => (
                                            <NDCBlock
                                                ndc={ndc}
                                                idx={idx}
                                                validator={this.validator}
                                                handleChangeNDC={this.handleNDCChange}
                                                handleRemoveNDC={this.handleRemoveNDC}
                                                handleDrugCoverageChange={this.handleDrugCoverageChange}
                                            />
                                        ))}
                                        <Button onClick={this.handleAddNDC} bsStyle="primary" className="pull-right"><span className="fa fa-plus"></span> Add Sku</Button>
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={6} md={6}>
                                        <Button type='submit' bsStyle='success'>Save Drug</Button>
                                    </Col>
                                </FormGroup>
                            </form>
                        }
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    drugs: state.drugs,
    alert: state.alert
});

const mapActionsToProps = ({
    fetchDrug: DrugAction.fetchdrug,
    drugupdate: DrugAction.drugupdate
});

export default connect(mapStateToProps, mapActionsToProps)(EditDrugPage);
