import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as HELPER from "../../utils/helpers";
import { FormControl, Col, ButtonToolbar, FormGroup } from 'react-bootstrap';
import * as UserAccountAction from '../../actions/useraccountAction';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import _ from 'lodash';

class ListUserAccountsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'service_provider_type': '',
    }
  }

  columns = () => {
    return [
      {
        dataField: "service_provider_name",
        text: "Name of Service Provider",
        sort: false
      },
      {
        dataField: "user.contact_number",
        text: "Contact Number",
        sort: false,
        formatter: cell => {
          return cell ? cell : '--';
        }
      },
      {
        dataField: "user.email",
        text: "Email",
        sort: false
      },
      {
        dataField: "billing_address.city",
        text: "City",
        sort: false
      },
      {
        dataField: "user_account.amount_unbilled",
        text: "Unbilled Amount",
        sort: false
      },
      {
        dataField: "user_account.amount_due",
        text: "Due Amount",
        sort: false
      },
      {
        dataField: "user_account.amount_over_due",
        text: "Over Due Amount",
        sort: false
      },
      {
        dataField: "user_account.due_date",
        text: "Due Date",
        formatter: cell => {
          return HELPER.getDateFormat(cell);
        }
      },
      {
        dataField: "actions",
        text: "Actions",
        isDummyField: true,
        formatter: this.actionFormatter
      }
    ];
  };

  actionFormatter = (cell, row) => {
    return <ButtonToolbar>
      {<Link className="btn btn-info" to={'/service-providers/' + row.service_provider_id + '/accounts'}><i className="fa fa-eye" aria-hidden="true"></i></Link>}
    </ButtonToolbar>;
  }

  handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText }
  ) => {
    if (type === "search") {
      page = 1;
    }
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleFilterSubmit = () => {
    const filter = {
      'service_provider_type': this.state.service_provider_type
    };
    this.props.paginate({ sortBy: { sortField: 'updated_at', sortOrder: 'desc' }, filter })
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const { data } = this.props.user_account;
    return (
      <div>
        <Helmet>
          <title>List User Account</title>
        </Helmet>
        <Content title="User Accounts">
          <Box>
            <Box.Body>
              <FormGroup>
                <Col sm={3} >
                  <Box.Head title="Select Service Provider Type" />
                </Col>
                <Col sm={3}>
                  <FormControl name="service_provider_type" componentClass="select"
                    value={this.state.service_provider_type}
                    onChange={this.handleChange}>
                    <option value="" defaultValue disabled>Select Service Provider Type</option>
                    <option value="doctor">Doctor</option>
                    <option value="pharmacy">Pharmacy</option>
                    <option value="pathology">Pathology</option>
                    <option value="radiology">Radiology</option>
                  </FormControl>
                </Col>
                <Col sm={1} >
                  <button type="submit" className="btn btn-primary  pull-right" onClick={this.handleFilterSubmit}>Search</button>
                </Col>
              </FormGroup>
            </Box.Body>
          </Box>

          {!_.isEmpty(data) && <Box>
            {data[0].service_provider_type === "doctor" && <Box.Head title="Doctor's List" />}
            {data[0].service_provider_type === "radiology" && <Box.Head title="Radiology's List" />}
            {data[0].service_provider_type === "pharmacy" && <Box.Head title="Pharmacy's List" />}
            {data[0].service_provider_type === "pathology" && <Box.Head title="Pathology's List" />}
            <Box.Body>
              <ToolkitProvider
                search
                keyField="id"
                data={data}
                columns={this.columns()}
              >
                {
                  props => [
                    // <div className="pull-right">
                    //     <Search.SearchBar {...props.searchProps} delay={1000} />
                    //     </div>,
                    <BootstrapTable
                      {...props.baseProps}
                      remote
                      onTableChange={this.handleTableChange}
                      // pagination={paginationFactory({
                      //   page: parseInt(meta.current_page),
                      //   sizePerPage: parseInt(meta.per_page),
                      //   totalSize: parseInt(meta.total)
                      // })}
                      rowStyle={this.rowStyle}
                    />
                  ]
                }
              </ToolkitProvider>
            </Box.Body>
          </Box>}
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user_account: state.user_account
});

const mapActionsToProps = {
  paginate: UserAccountAction.list,
  reset: UserAccountAction.resetList,
};

export default connect(mapStateToProps, mapActionsToProps)(ListUserAccountsPage);