import React from 'react';
import { connect } from 'react-redux';
import * as authActions from '../actions/authActions';
import { Link } from "react-router-dom";
import * as HELPER from "../utils/helpers";

class SideBar extends React.Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(parentnav, childnav) {
        this.props.navaction(parentnav, childnav);
    }

    render() {
        const navdata = this.props.nav;

        return (
            <aside className="main-sidebar">
                <section className="sidebar">
                    <ul className="sidebar-menu" data-widget="tree">
                        <li className={((navdata.parent === 'dashboard') ? '' : '')}>
                            <Link to="/dashboard">
                                <i className="fa fa-dashboard"></i>
                                <span>Dashboard</span>
                            </Link>
                        </li>
                        
                        {HELPER.hasRole(this.props.admin, ["super-admin", "admin"]) && <>
                            <li className={((navdata.parent === 'covid-dashboard') ? '' : '')}>
                                <Link to="/covid-dashboard">
                                    <i className="fa fa-dashboard"></i>
                                    <span>Covid Dashboard</span>
                                </Link>
                            </li>
                            <li className={((navdata.parent === 'authority-access-dashboard') ? '' : '')}>
                                <Link to="/authority-access-dashboard">
                                    <i className="fa fa-dashboard"></i>
                                    <span>Authority Access Dashboard</span>
                                </Link>
                            </li>
                        </>}
                        {HELPER.hasPermission(this.props.admin, ['admin-list', 'doctor-list', 'pharmacy-list', 'radiology-list', 'pathology-list', 'patient-list', 'covid-center-list', 'ambulance-list', 'hospital-list', 'airline-list',]) && <li className={"treeview"}>
                            <a href="#">
                                <i className="fa fa-user-o"></i>
                                <span>User Management</span>
                                <span className="pull-right-container">
                                    <i className="fa fa-angle-left pull-right"></i>
                                </span>
                            </a>
                            <ul className={"treeview-menu"}>
                                {HELPER.hasPermission(this.props.admin, ['admin-list']) && <li className={((navdata.child === 'admins') ? 'active' : '')} ><Link onClick={() => this.handleClick('user_management', 'admin')} to="/admins"><i className="fa fa-circle-o"></i> Admin User</Link></li>}

                                {HELPER.hasPermission(this.props.admin, ['doctor-list']) && <li className={((navdata.child === 'doctor') ? 'active' : '')} ><Link onClick={() => this.handleClick('user_management', 'doctor')} to="/doctors"><i className="fa fa-circle-o"></i> Doctor</Link></li>}

                                {HELPER.hasPermission(this.props.admin, ['associate-doctor-list']) && <li className={((navdata.child === 'associate_doctor') ? 'active' : '')} ><Link onClick={() => this.handleClick('user_management', 'associate_doctor')} to="/associate-doctors"><i className="fa fa-circle-o"></i> Associate Doctor</Link></li>}

                                {HELPER.hasPermission(this.props.admin, ['pharmacy-list']) && <li className={((navdata.child === 'pharmacy') ? 'active' : '')} ><Link onClick={() => this.handleClick('user_management', 'pharmacy')} to="/pharmacies"><i className="fa fa-circle-o"></i> Pharmacy</Link></li>}

                                {HELPER.hasPermission(this.props.admin, ['radiology-list']) && <li className={((navdata.child === 'radiologies') ? 'active' : '')} ><Link onClick={() => this.handleClick('user_management', 'radiologies')} to="/radiologies"><i className="fa fa-circle-o"></i> Radiology</Link></li>}

                                {HELPER.hasPermission(this.props.admin, ['pathology-list']) && <li className={((navdata.child === 'pathologies') ? 'active' : '')} ><Link onClick={() => this.handleClick('user_management', 'pathologies')} to="/pathologies"><i className="fa fa-circle-o"></i> Pathology</Link></li>}

                                {HELPER.hasPermission(this.props.admin, ['ambulance-list']) && <li className={((navdata.child === 'ambulance') ? 'active' : '')} ><Link onClick={() => this.handleClick('user_management', 'ambulance')} to="/ambulances"><i className="fa fa-circle-o"></i> Ambulance</Link></li>}

                                {HELPER.hasPermission(this.props.admin, ['hospital-list']) && <li className={((navdata.child === 'hospital') ? 'active' : '')} ><Link onClick={() => this.handleClick('user_management', 'hospital')} to="/hospitals"><i className="fa fa-circle-o"></i>Hospitals</Link></li>}

                                {HELPER.hasPermission(this.props.admin, ['covid-center-list']) && <li className={((navdata.child === 'covid_center') ? 'active' : '')} ><Link onClick={() => this.handleClick('user_management', 'covid_center')} to="/covid-centers"><i className="fa fa-circle-o"></i>Covid Center</Link></li>}

                                {HELPER.hasPermission(this.props.admin, ['airline-list']) && <li className={((navdata.child === 'airline') ? 'active' : '')} ><Link onClick={() => this.handleClick('user_management', 'airline')} to="/authority-access"><i className="fa fa-circle-o"></i>Authority Access</Link></li>}

                                {HELPER.hasPermission(this.props.admin, ['patient-list']) && <li className={((navdata.child === 'patient') ? 'active' : '')} ><Link onClick={() => this.handleClick('user_management', 'patient')} to="/patients"><i className="fa fa-circle-o"></i> Patient</Link></li>}
                            </ul>
                        </li>}

                        {HELPER.hasPermission(this.props.admin, ['role-list']) && <li className={"treeview"}>
                            <a href="#">
                                <i className="fa fa-user-o"></i>
                                <span>Manage Roles</span>
                                <span className="pull-right-container">
                                    <i className="fa fa-angle-left pull-right"></i>
                                </span>
                            </a>
                            <ul className={"treeview-menu"}>
                                {HELPER.hasPermission(this.props.admin, ['role-list']) && <li className={((navdata.child === 'roles') ? 'active' : '')} ><Link onClick={() => this.handleClick('manage_roles', 'roles')} to="/roles"><i className="fa fa-circle-o"></i>Roles</Link></li>}
                            </ul>
                        </li>}

                        {HELPER.hasPermission(this.props.admin, ['generic-drugs-list', 'products-list', 'drug-price-list']) && <li className={"treeview"}>
                            <a href="#">
                                <i className="fa fa-medkit"></i>
                                <span>Drugs</span>
                                <span className="pull-right-container">
                                    <i className="fa fa-angle-left pull-right"></i>
                                </span>
                            </a>
                            <ul className={"treeview-menu"}>
                                {HELPER.hasPermission(this.props.admin, ['generic-drugs-list']) && <li className={((navdata.child === 'generic') ? 'active' : '')} ><Link onClick={() => this.handleClick('drug', 'generic')} to="/genericdrugs"><i className="fa fa-circle-o"></i>Generic Drugs</Link></li>}

                                {HELPER.hasPermission(this.props.admin, ['products-list']) && <li className={((navdata.child === 'product') ? 'active' : '')} ><Link onClick={() => this.handleClick('drug', 'product')} to="/drugs"><i className="fa fa-circle-o"></i>Products</Link></li>}

                                {HELPER.hasPermission(this.props.admin, ['drug-price-list']) && <li className={((navdata.child === 'price') ? 'active' : '')} ><Link onClick={() => this.handleClick('drug', 'price')} to="/drug-price"><i className="fa fa-circle-o"></i>Price</Link></li>}
                            </ul>
                        </li>}

                        {HELPER.hasPermission(this.props.admin, ['pathology-tests-list', 'pathology-test-price-list', 'radiology-tests-list', 'radiology-test-price-list']) && <li className={"treeview"}>
                            <a href="#">
                                <i className="fa fa-stethoscope"></i>
                                <span>Tests</span>
                                <span className="pull-right-container">
                                    <i className="fa fa-angle-left pull-right"></i>
                                </span>
                            </a>
                            <ul className={"treeview-menu"}>
                                {HELPER.hasPermission(this.props.admin, ['pathology-tests-list']) && <li className={((navdata.child === 'pathology') ? 'active' : '')} ><Link onClick={() => this.handleClick('tests', 'pathology')} to="/pathology/tests"><i className="fa fa-circle-o"></i>Pathology Tests</Link></li>}

                                {HELPER.hasPermission(this.props.admin, ['pathology-test-price-list']) && <li className={((navdata.child === 'pathology_price') ? 'active' : '')} ><Link onClick={() => this.handleClick('tests', 'pathology_price')} to="/pathologytest/price"><i className="fa fa-circle-o"></i>Pathology Tests Price</Link></li>}

                                {HELPER.hasPermission(this.props.admin, ['radiology-tests-list']) && <li className={((navdata.child === 'radiology') ? 'active' : '')} ><Link onClick={() => this.handleClick('tests', 'radiology')} to="/radiology/tests"><i className="fa fa-circle-o"></i>Radiology Tests</Link></li>}

                                {HELPER.hasPermission(this.props.admin, ['radiology-test-price-list']) && <li className={((navdata.child === 'radiology_price') ? 'active' : '')} ><Link onClick={() => this.handleClick('tests', 'radiology_price')} to="/radiologytest/price"><i className="fa fa-circle-o"></i>Radiology Tests Price</Link></li>}

                            </ul>
                        </li>}

                        {HELPER.hasPermission(this.props.admin, ['prescriptions-list']) && <li className={((navdata.parent === 'prescriptions') ? 'active' : '')}>
                            <Link to="/prescriptions">
                                <i className="fa fa-list-alt"></i>
                                <span>Prescriptions</span>
                            </Link>
                        </li>}

                        {HELPER.hasPermission(this.props.admin, ['patient-search']) && <li className={((navdata.parent === 'patient_search') ? 'active' : '')}>
                            <Link to="/patient/search">
                                <i className="fa fa-search-plus"></i>
                                <span>Patient Search</span>
                            </Link>
                        </li>}

                        {HELPER.hasPermission(this.props.admin, ['patient-card-list', 'request-list']) && <li className={"treeview"}>
                            <a href="#">
                                <i className="fa fa-credit-card-alt"></i>
                                <span>Patient Card</span>
                                <span className="pull-right-container">
                                    <i className="fa fa-angle-left pull-right"></i>
                                </span>
                            </a>
                            <ul className={"treeview-menu"}>
                                {HELPER.hasPermission(this.props.admin, ['request-list']) && <li className={((navdata.child === 'request') ? 'active' : '')} ><Link onClick={() => this.handleClick('patient-card', 'request')} to="/patients/cards/request"><i className="fa fa-circle-o"></i>Request</Link></li>}

                                {HELPER.hasPermission(this.props.admin, ['patient-card-list']) && <li className={((navdata.child === 'list') ? 'active' : '')} ><Link onClick={() => this.handleClick('patient-card', 'list')} to="/patients/cards"><i className="fa fa-circle-o"></i>List</Link></li>}
                            </ul>
                        </li>}
                        {/* <li className={((navdata.parent === 'user_payments') ? 'active' : '')}>
                            <Link to="/userpayments">
                                <i className="fa fa-usd"></i>
                                <span>User Payments</span>
                            </Link>
                        </li> */}
                        {HELPER.hasPermission(this.props.admin, ['commissions-list']) && <li className={((navdata.parent === 'commissions') ? 'active' : '')}>
                            <Link to="/commissions">
                                <i className="fa fa-money"></i>
                                <span>Commissions</span>
                            </Link>
                        </li>}

                        {HELPER.hasPermission(this.props.admin, ['other-drugs-list', 'other-pathology-tests-list', 'other-radiology-tests-list', 'other-doctors-list']) && <li className={"treeview"}>
                            <a href="#">
                                <i className="fa fa-medkit"></i>
                                <span>Other Reports</span>
                                <span className="pull-right-container">
                                    <i className="fa fa-angle-left pull-right"></i>
                                </span>
                            </a>
                            <ul className={"treeview-menu"}>
                                {HELPER.hasPermission(this.props.admin, ['other-drugs-list']) && <li className={((navdata.child === 'drugs') ? 'active' : '')} ><Link onClick={() => this.handleClick('other-reports', 'drugs')} to="/other-drugs"><i className="fa fa-circle-o"></i>Other Drugs</Link></li>}

                                {HELPER.hasPermission(this.props.admin, ['other-pathology-tests-list']) && <li className={((navdata.child === 'pathology-test') ? 'active' : '')} ><Link onClick={() => this.handleClick('other-reports', 'pathology-test')} to="/other-pathology-test"><i className="fa fa-circle-o"></i>Other Pathology Test</Link></li>}

                                {HELPER.hasPermission(this.props.admin, ['other-radiology-tests-list']) && <li className={((navdata.child === 'radiology-test') ? 'active' : '')} ><Link onClick={() => this.handleClick('other-reports', 'radiology-test')} to="/other-radiology-test"><i className="fa fa-circle-o"></i>Other Radiology Test</Link></li>}

                                {HELPER.hasPermission(this.props.admin, ['other-doctors-list']) && <li className={((navdata.child === 'doctors') ? 'active' : '')} ><Link onClick={() => this.handleClick('other-reports', 'doctors')} to="/other-doctors"><i className="fa fa-circle-o"></i>Other Doctors</Link></li>}
                            </ul>
                        </li>}

                        {HELPER.hasPermission(this.props.admin, ['duplicate-request-list', 'duplicate-request-action']) && <li className={((navdata.parent === 're-issue-card-requests') ? 'active' : '')}>
                            <Link to="/reissue-card-requests">
                                <i className="fa fa-credit-card-alt"></i>
                                <span>Re-Issue Card Requests</span>
                            </Link>
                        </li>}

                        {HELPER.hasPermission(this.props.admin, ['trial-account-create', 'trial-account-list', 'trial-account-view', 'trial-account-send-email', 'trial-account-extend', 'trial-account-edit']) && <li className={"treeview"}>
                            <a href="#">
                                <i className="fa fa-user-plus"></i>
                                <span>Trial Accounts</span>
                                <span className="pull-right-container">
                                    <i className="fa fa-angle-left pull-right"></i>
                                </span>
                            </a>
                            <ul className={"treeview-menu"}>
                                {HELPER.hasPermission(this.props.admin, ['trial-account-create']) && <li className={((navdata.child === 'create') ? 'active' : '')} ><Link onClick={() => this.handleClick('trial-accounts', 'create')} to="/trial/accounts/add"><i className="fa fa-circle-o"></i>Create Trial Account</Link></li>}
                                {HELPER.hasPermission(this.props.admin, ['trial-account-list', 'trial-account-edit', 'trial-account-view', 'trial-account-extend', 'trial-account-send-email']) && <li className={((navdata.child === 'list') ? 'active' : '')} ><Link onClick={() => this.handleClick('trial-accounts', 'list')} to="/trial/accounts"><i className="fa fa-circle-o"></i>Trial Account List</Link></li>}
                            </ul>
                        </li>}

                        {HELPER.hasPermission(this.props.admin, ['billing-and-account']) && <li className={((navdata.parent === 'billing-and-account') ? 'active' : '')}>
                            <Link to="/service-providers/accounts">
                                <i className="fa fa-book"></i>
                                <span>Billing And Account</span>
                            </Link>
                        </li>}

                        {HELPER.hasPermission(this.props.admin, ['icd-cpt-view']) && <li className={((navdata.parent === 'cpt') ? 'active' : '')}>
                            <Link to="/cpts">
                                <i className="fa fa-plus-square"></i>
                                <span>CPT</span>
                            </Link>
                        </li>}

                        {HELPER.hasPermission(this.props.admin, ['icd-cpt-view']) && <li className={((navdata.parent === 'icd') ? 'active' : '')}>
                            <Link to="/icds">
                                <i className="fa fa-plus-square"></i>
                                <span>ICD</span>
                            </Link>
                        </li>}

                        {/* {HELPER.hasPermission(this.props.admin, ['pharmacies-order-list']) && <li className={((navdata.parent === 'pharmacies-order-list') ? 'active' : '')}>
                            <Link to="/service-providers/orders">
                                <i className="fa fa-bars" aria-hidden="true"></i>
                                <span>Pharmacies Orders</span>
                            </Link>
                        </li>} */}
                        
                        {HELPER.hasRole(this.props.admin, ["super-admin", "admin"]) && <li className={((navdata.parent === 'patient-alias') ? 'active' : '')}>
                            <Link to="/patient-requests">
                                <i className="fa fa-bars" aria-hidden="true"></i>
                                <span>Patient Alias</span>
                            </Link>
                        </li>}
                        {HELPER.hasRole(this.props.admin, ["super-admin"]) && <li className={((navdata.parent === 'logo-management') ? 'active' : '')}>
                            <Link to="/logo-management" onClick={() => this.handleClick('logo-management')}>
                                <i className="fa fa-bars" aria-hidden="true"></i>
                                <span>Logo Management</span>
                            </Link>
                        </li>}

                        {HELPER.hasRole(this.props.admin, ["super-admin", "admin"]) && <li className={"treeview"}>
                            <a href="#">
                                <i className="fa fa-user-plus"></i>
                                <span>Activity Logs</span>
                                <span className="pull-right-container">
                                    <i className="fa fa-angle-left pull-right"></i>
                                </span>
                            </a>
                            <ul className={"treeview-menu"}>
                                <li className={((navdata.child === 'covid-activity-log') ? 'active' : '')} >
                                    <Link onClick={() => this.handleClick('activity-logs', 'covid-activity-log')} to="/activity-logs/covid-center"><i className="fa fa-circle-o"></i>Covid Activity Log</Link>
                                </li>
                                <li className={((navdata.child === 'authority-access-activity-log') ? 'active' : '')} >
                                    <Link onClick={() => this.handleClick('activity-logs', 'authority-access-activity-log')} to="/activity-logs/authority-access"><i className="fa fa-circle-o"></i>Authority Access Activity Log</Link>
                                </li>
                            </ul>
                        </li>}
                    </ul>
                </section>
            </aside >
        )
    }
}

const mapStateToProps = (state) => ({
    nav: state.nav,
    admin: state.auth.admin
})

const mapActionsToProps = ({
    navaction: authActions.navaction,
})


export default connect(mapStateToProps, mapActionsToProps)(SideBar);
//export default SideBar;
